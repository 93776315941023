import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, InputGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faMinusCircle, faPlusCircle, faRedo } from '@fortawesome/free-solid-svg-icons'
import { Divider, List, Tooltip } from 'antd'

import CustomRateModal from './CustomRateModal'

export default function ProductItemsList({ items, setItems, handleAddItem, handleChangleItem }) {
	const currentUser = useSelector(state => state.auth.details)
	const [subtotal, setSubtotal] = useState(0)
	const [total, setTotal] = useState(0)
	const [customRateModal, setCustomRateModal] = useState(null)

	useEffect(()=>{
		let newSubtotal = items.reduce((a, b) => a + ( (b.rate.gross_price * b.quantity) || 0), 0)
		let newTotal = items.reduce((a, b) => a + ( (b.rate.net_price * b.quantity) || 0), 0)

		setSubtotal(newSubtotal)
		setTotal(newTotal)
	},[items])

	const handleRemove = itemIndex => {
		setItems(items.filter((i, index) => index !== itemIndex))
	}

	const handleNewQty = (itemIndex, newQty) => {
		if(newQty > 0){
			const newItems = [...items]
			newItems[itemIndex].quantity = parseInt(newQty)
			setItems(newItems)
		}
	}

	const handleRedo = (itemIndex, parentKey) => {
		handleRemove(itemIndex)
		handleChangleItem(parentKey)
	}

	return (
		<React.Fragment>
			<List
				itemLayout="vertical"
				size="large"
				dataSource={items}
				className="bg-light items-list"
				renderItem={ (item, itemIndex) => (
					<List.Item
						key={item.product.id}
						extra={
							<React.Fragment>
								<div className="float-right pt-3">
									<InputGroup>
										<Button className="arrowBtn" outline onClick={() => handleNewQty(itemIndex, item.quantity - 1)}>
											<FontAwesomeIcon icon={faMinusCircle} size="sm" />
										</Button>
										<input 
											type="number" 
											value={item.quantity} 
											onChange={e => handleNewQty(itemIndex, e.target.value)} 
											className="form-control text-center"
											style={{ width: '40px' }}
											min="1"
										/>
										<Button className="arrowBtn" outline onClick={() => handleNewQty(itemIndex, item.quantity + 1)}>
											<FontAwesomeIcon icon={faPlusCircle} size="sm" />
										</Button>
									</InputGroup>
								</div>
								<div className="float-left mr-3">								
									<h6 className="mb-0 text-right bold">
										{`$ ${currency((item.rate.net_price * item.quantity))}`}
									</h6>
									<div className="mb-0 text-right">
										<span className="line-through">{`$ ${currency((item.rate.gross_price * item.quantity))}`}</span>
										{ currentUser.can_add_rates && (
											<React.Fragment>
												<Divider type="vertical" />
												<span className='text-link' onClick={() => setCustomRateModal(itemIndex+1)}>
													Modificar Tarifa
												</span>
											</React.Fragment>
										)}
										<br />
										<small>Descuento:</small>{" "}
										<span className="bold">${currency( ((item.rate.gross_price - item.rate.net_price) * item.quantity) )} ({item.rate.discount}%)</span>
									</div>
								</div>
							</React.Fragment>
						}
					>
						<List.Item.Meta
							avatar={(
								<React.Fragment>
									<Tooltip title="Quitar">
										<span className="text-link" onClick={()=>handleRemove(itemIndex)}>
											<FontAwesomeIcon icon={faTrash} />
										</span>
									</Tooltip>
									{ handleChangleItem && (
										<React.Fragment>
											<Divider className='my-2' />
											<Tooltip title="Quitar y agregar de nuevo" placement="bottomRight">
												<span
													className="text-link"
													onClick={()=>handleRedo(itemIndex, item.product.parent_key)}
												>
													<FontAwesomeIcon icon={faRedo} />
												</span>
											</Tooltip>
										</React.Fragment>
									)}
								</React.Fragment>
							)}
							title={item.product.description}		
							className="mb-0"							
							description={(
								<React.Fragment>
									<small>
										Referencia: {item.product.reference} |{" "}
										Talla: {item.product.size} |{" "}
										Color: {item.product.color}
									</small><br />
									<small>
										Tarifa: {item.rate.description}
									</small>
								</React.Fragment>
							)}
						/>
					</List.Item>
				)}
			/>
			<div className="text-right mt-4">
				<div className="bg-light inline-block mr-2 p-2">
					Subtotal:
					<span className="bold">$ {currency(subtotal)} COP</span>
				</div>
				<div className="bg-light inline-block mr-2 p-2">
					Descuento:
					<span className="bold">$ {currency( (subtotal-total) )} COP ({ round( (subtotal-total)/subtotal*100 ) }%)</span>
				</div>
				<div className="inline-block p-2" style={{ backgroundColor: '#80e096' }}>
					Total:
					<span className="bold">$ {currency(total)} COP</span>
				</div>
			</div>
			<div className="text-right mt-2">
				<Button color="primary" size="sm" outline onClick={handleAddItem}>
					<FontAwesomeIcon icon={faPlus} size="xs" /> Agregar Producto
				</Button>
			</div>
			{ customRateModal && (
				<CustomRateModal 
					visible
					onClose={() => setCustomRateModal(null)}
					itemIndex={customRateModal-1}
					items={items}
					setItems={setItems}
				/>
			)}
		</React.Fragment>
	)
}

const currency = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
const round = value => Math.round((value + Number.EPSILON) * 1) / 1