import React from 'react'
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
import { Divider, Timeline, Empty } from 'antd'
import moment from 'moment'

export default function QuoteStatesWidget({ quote }) {
	return (
		<Card>
			<CardBody>
				<CardTitle>Historíal de Estados</CardTitle>
				<CardSubtitle>Cambio de estado de la cotización</CardSubtitle>
				<Divider className="mt-2 mb-3" />
				{ quote.states.length > 0 ? (
				<Timeline mode="left">
					<Timeline.Item color="red">
						Cotización creada: {moment(quote.created_at).format('D/MMM/Y h:mm a')}
					</Timeline.Item>
					{ quote.states.map( state => 
						<Timeline.Item key={state.id} color="red">
							[{moment(state.created_at).format('D/M/Y h:mm a')}] {state.status}<br/>
							<small className="text-muted">{state.comment}</small>
						</Timeline.Item>
					)}
				</Timeline>
				) : <Empty description="No ha cambiado el estado inicial" />}
			</CardBody>
		</Card>
	)
}