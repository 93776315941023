import springApi, { getDomain } from '../../services/springApi'

export const getProducts = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/products', {params})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const syncAndGetProducts = (storeId, productKey) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/products/sg/${storeId}/${productKey}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchProducts = async (term, token) => (
	fetch( getDomain()+`/products?filter[name]=${term}&sort=name`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(product => ({
				label: `${product.name} (${product.color} - ${product.size})`,
				value: product.id,
			})),
		)
)

export const searchProductsByCode = async (term, token) => (
	fetch( getDomain()+`/products?filter[code]=${term}&sort=name&per_page=50`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(product => ({
				label: `${product.code} - ${product.name} (${product.color} - ${product.size})`,
				value: product.id,
			})),
		)
)