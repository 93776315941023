import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import { storeCouponOrderInvoice } from '../services'
import { handleError } from '../../../helpers'

export default function CouponOrderInvoiceModal({ visible, onClose, couponOrderId, reload }) {
	const { handleSubmit, register, formState: { errors } } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		values.coupon_order_id = couponOrderId
		values.invoice_reference = values.invoice_reference.replace(' ', '')
		values.invoice_reference = values.invoice_reference.replace('-', '')
		storeCouponOrderInvoice(values)
			.then(res => {
				message.info(res.data.message)
				reload()
				onClose()
			})
			.catch(error => {
				handleError(error)
				setLoading(false)
			})
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Registrar venta con cupo</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Número de Factura / Recibo de Caja</Form.Label>
							<Form.Control 
								name="invoice_reference"
								placeholder='S92F1234'
								ref={register({ required:true })}
							/>
							{ errors.invoice_reference && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Button type='submit' color="primary" disabled={loading}>
							Registra Venta con Cupo
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}