import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

import ChangeStoreModal from '../../containers/Authentication/partials/ChangeStoreModal'

export default function MainMenu({ closeMenu }) {
	const user = useSelector(state => state.auth.details)
	const [showStoreModal, setShowStoreModal] = useState(false)
	const brand = window.location.host.split('.')[1]
	const theme = brand === 'rosen' ? 'dark' : 'light'

	return (
		<React.Fragment>
			<Container theme={theme}>
				<UserContainer theme={theme}>
					<UserName className='mb-0'>{user.name}</UserName>
					<p>c.c. {user.document}</p>
					<p>Tienda: {user.active_store.code} {user.active_store.name}</p>
					<p className="mb-3 text-link" onClick={()=>setShowStoreModal(true)}>Cambiar de Tienda</p>
					<Link to="/auth/logout">Cerrar Sesión</Link>
				</UserContainer>
				<MenuList theme={theme}>				
					<li><Link to="/cotizaciones" onClick={closeMenu}>Seguimiento de Cotizaciones</Link></li> 
					<li><Link to="/prospectos" onClick={closeMenu}>Directorio de Contactos</Link></li> 
					<li><Link to="/buscar" onClick={closeMenu}>Buscar Cotización</Link></li> 
					<li><Link to="/perfil" onClick={closeMenu}>Herramientas de Análisis</Link></li>
					<li><Link to="/reportes/general" onClick={closeMenu}>Reporte de Cotizaciones</Link></li> 
					<li><Link to="/reportes/profiles" onClick={closeMenu}>Reporte de Visitas</Link></li> 
					<li><Link to="/entregas" onClick={closeMenu}>Simulador Fecha de Entrega</Link></li> 
					<li><Link to="/alertas_inventario" onClick={closeMenu}>Alertas de Inventario</Link></li> 
					{ brand !== 'rosen' && (
						<React.Fragment>
							<li><a href="https://momentos.spring.com.co/catalogo" target="_blank" rel="noreferrer">
								Catálogo de Patas
							</a></li>
							<li><Link to="/venta_empleados" onClick={closeMenu}>Venta y Referidos de Empleados</Link></li> 
						</React.Fragment>
					)}					
				</MenuList>
				<div>
					<Link to="/cotizador" onClick={closeMenu}>
						<Button color="primary" className="mb-3">Cotización Nueva</Button>
					</Link>
				</div>
				<Link to="/perfil" onClick={closeMenu}>
					<Button color="primary" className="mb-3" outline>Nueva Visita</Button>
				</Link>
				<p>Correo de soporte: soporte@smart4.com.co</p>
			</Container>
			<ChangeStoreModal 
				visible={showStoreModal}
				onClose={() => setShowStoreModal(false)}
				user={user}
				reload={()=>window.location.reload()}
			/>
		</React.Fragment>
	)
}

const Container = styled.div`
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100vh;
	z-index: 9;	
	padding-top: 50px;
	width: 270px;
	background-color: white;
	text-align: center;
	-webkit-box-shadow: 3px 0 12px 0 #b3b3b3;
	box-shadow: 3px 0 12px 0 ${props => props.theme === 'dark' ? '#333' : '#b3b3b3'};
	overflow-y: scroll;
	& p, h6 {
		color: ${props => props.theme === 'dark' ? '#ffffff' : '#3b4351'};
	}
`

const UserContainer = styled.div`
	padding: 40px 20px 30px;
	background-color: ${props => props.theme === 'dark' ? '#303c41' : '#e8e8e8'};
`

const UserName = styled.h6`
	font-size: 17px;
	text-transform: uppercase;
	font-weight: 600;
`

const MenuList = styled.ul`
	list-style: none;
	margin: 0px 0px 30px;
	padding: 0;
	& li {
		padding: 0;
    	margin: 0;
		background-color: ${props => props.theme === 'dark' ? '#637075' : '#ffffff'};
	}
	& li a {
		display: inline-block;
		padding: 14px 0;
		margin: 0;
		border-bottom: 1px solid  ${props => props.theme === 'dark' ? '#727d81' : '#e8e8e8'};
		width: 100%;
		color: ${props => props.theme === 'dark' ? '#ffffff!important' : '#e42537'};
	}
	& li a:hover {
		cursor: pointer;
		background-color: #333;
		background-color: ${props => props.theme === 'dark' ? '#303c41' : '#333'};
		color: #fff !important;
	}
`