import React from 'react'
import moment from 'moment'

export default function PublicNotesList({ notes }) {

	if(!notes || notes.length === 0) 
		return null

	return (
		<React.Fragment>
			<p className="bold mb-2">Notas del asesor</p>
			{ notes.map(note => (
				<div key={note.id} className='bg-light mb-2 p-2'>
					<p className='mb-0'>{note.note}</p>
					<p className='small mb-0'>
						<em>{note.user_name}</em> |{" "}
						{moment(note.created_at).format('DD-MMM-YYYY hh:mm A').toUpperCase()}
					</p>
				</div>
			)) }
		</React.Fragment>
	)
}