import React from 'react'
import GaugeChart from 'react-gauge-chart'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/es'

export default function WinProbability({ quote }) {
	if(!quote) return null

	const metrics = [
		{ label: 'Estado Actual', value: quote.current_state ? quote.current_state.status : 'Estado Inicial' },
		{ label: 'Fecha de vencimiento', value: moment(quote.expire_at).fromNow() },
		{ label: 'Días desde cotizado', value: moment(quote.created_at).fromNow() },
		{ label: 'Última apertura', value: quote.last_open_at ? moment(quote.last_open_at).fromNow() : 'Sin apertura' },
		{ label: 'Categorías cotizadas', value: quote.product_categories.join(', ') },
		{ label: 'Total de Aperturas', value: quote.opens_count, suffix: 'aperturas' },
	]	

	return (
		<React.Fragment>
			<GaugeChart
				nrOfLevels={20} 
				percent={(quote.win_probability / 100)}
				colors={['#c5dfe3', '#7ed1de', '#0dbbd6']}
				arcPadding={0.03}
				textColor="#333"
				needleColor="#ccc"
				formatTextValue={ value => {
					if( parseInt(value) === 0) return 'Cerrada'
					if( parseInt(value) < 30) return 'A'
					if( parseInt(value) < 70) return 'AA'
					if( parseInt(value) === 100) return 'Ganada'
					return 'AA+'
				}}
			/>

			{ metrics.map( (metric, i) => (
				<DetailCard key={i}>
					<DetailSubtitle>{metric.label}</DetailSubtitle>
					<DetailTitle>
						{metric.value}{" "}
						<small>{ metric.suffix }</small>
					</DetailTitle>					
				</DetailCard>
			)) }
		</React.Fragment>
	)
}

const DetailCard = styled.div`
	margin-bottom: 10px;
	border-bottom: 1px solid #f8f8f8;
`
const DetailTitle = styled.p`
	font-size: 14px;
	margin-bottom: 0px;
`
const DetailSubtitle = styled.p`
	color: #888;
	margin-bottom: 0px;
	font-size: 11px;
`