import springApi from '../../services/springApi'

export const getEmployeeCoupons = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/insales/employee_coupons', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getReferralIncentives = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/insales/referral_incentives', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCouponOrderInvoice = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/insales/coupon_order_invoice', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReferralOrderInvoice = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/insales/referral_order_invoice', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)