import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardTitle, CardSubtitle, Button, Row, Col } from 'reactstrap'
import { faBabyCarriage, faHandHoldingHeart, faPlaneDeparture, faArchway, faBriefcase, faFlag, faMale, faFemale, faMoneyBill, faChartBar } from '@fortawesome/free-solid-svg-icons'

import OptionPicker from '../SpringAI/partials/OptionPicker'
import NewVisitForm from './partials/NewVisitForm'
import BestSellersDrawer from '../SpringAI/partials/BestSellersDrawer'
import MostQuotedDrawer from '../SpringAI/partials/MostQuotedDrawer'

export default function VisitsProfiling() {
	const [showBestSellersDrawer, setShowBestSellersDrawer] = useState(false)
	const [showMostQuotedDrawer, setShowMostQuotedDrawer] = useState(false)

	const genderOptions = [
		{ title: 'Hombre', slug: 'male', icon: faMale },
		{ title: 'Mujer', slug: 'female', icon: faFemale },
	]
	const profileOptions = [
		{ title: 'Buscador de Independencia (Jóvenes)', slug: 'emancipacion', icon: faPlaneDeparture },
		{ title: 'Unidos para Construir (Parejas)', slug: 'pareja', icon: faHandHoldingHeart },
		{ title: 'Crecimiento del Grupo Familiar', slug: 'crecimiento', icon: faBabyCarriage },
	]
	const generationOptions = [
		{ title: <span>20 a 40 años</span>, slug: 'millennials', icon: faFlag },
		{ title: <span>40 a 65 años</span>, slug: 'genx', icon: faBriefcase },
		{ title: <span>65 a 83 años</span>, slug: 'boomers', icon: faArchway },
	]

	const productsOptions = [
		{ title: 'Productos más Vendidos', slug: 'best_sellers', icon: faMoneyBill, handleClick: () => setShowBestSellersDrawer(true) },
		{ title: 'Productos más Cotizados', slug: 'most_quoted', icon: faChartBar, handleClick: () => setShowMostQuotedDrawer(true) },
	]

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className="float-right mt-2">
						<Link to='/cotizador'>
							<Button color="primary">Generar Cotización</Button>
						</Link>
					</div>
					<CardTitle>Visita en Tienda</CardTitle>
					<CardSubtitle>Herramientas para el equipo comercial</CardSubtitle>
				</CardBody>
			</Card>
			<Row>
				<Col>
					<Card>
						<CardBody>
							<CardTitle>Registrar Visita</CardTitle>
							<CardSubtitle className="mb-2">Registro de nuevas visitas</CardSubtitle>
							<NewVisitForm />
						</CardBody>
					</Card>
				</Col>
				<Col md="8">
					<Card>
						<CardBody>
							<CardTitle>Herramientas de Análisis</CardTitle>
							<CardSubtitle className="mb-2">Seleccione la herramienta a usar</CardSubtitle>
							<OptionPicker type="gender" options={genderOptions} />
							<OptionPicker type="profile" options={profileOptions} />
							<OptionPicker type="generation" options={generationOptions} />
							<OptionPicker type="product" options={productsOptions} />
						</CardBody>
					</Card>
				</Col>
			</Row>

			<BestSellersDrawer 
				visible={showBestSellersDrawer}
				onClose={() => setShowBestSellersDrawer(false)}
			/>
			<MostQuotedDrawer 
				visible={showMostQuotedDrawer}
				onClose={() => setShowMostQuotedDrawer(false)}
			/>
		</React.Fragment>
	)
}