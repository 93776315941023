import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'

import EmployeeCouponsWidget from './partials/EmployeeCouponsWidget'
import ReferralIncentivesWidget from './partials/ReferralIncentivesWidget'

export default function EmployeeSalesDashboard() {
	return (
		<React.Fragment>
			<Row>
				<Col sm="12" md="6">
					<Card>
						<CardHeader>
							<CardTitle>Consultar Cupos de Empleado</CardTitle>
							<CardSubtitle>Verificar cupos autorizados para venta con tarifas de empleados Spring</CardSubtitle>
						</CardHeader>
						<CardBody>
							<EmployeeCouponsWidget />
						</CardBody>
					</Card>
				</Col>
				<Col sm="12" md="6">
					<Card>
						<CardHeader>
							<CardTitle>Consultar Incentivos de Referido</CardTitle>
							<CardSubtitle>Verificar incentivos autorizados a referidos de empleados Spring</CardSubtitle>
						</CardHeader>
						<CardBody>
							<ReferralIncentivesWidget />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}