import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import StockAlertsList from './StockAlertsList'

export default function StockAlerts() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/alertas_inventario" component={StockAlertsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}