import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner, Card, CardBody, Button } from 'reactstrap'
import { DatePicker } from 'antd'
import moment from 'moment'

import ProfilesTopProductsWidget from './partials/ProfilesTopProductsWidget'
import ProfilesGroupsWidget from './partials/ProfilesGroupsWidget'
import ExportVisitsModal from './partials/ExportVisitsModal'

import { getProfilesReport } from './services'
import { handleError } from '../../helpers'

const { RangePicker } = DatePicker

export default function GeneralReport() {	
	const [report, setReport] = useState(null)
	const [filters, setFilters] = useState({})
	const [startDate, setStartDate] = useState(moment().startOf('month'))
	const [endDate, setEndDate] = useState(moment().endOf('month'))
	const [showExportModal, setShowExportModal] = useState(false)

	useEffect(()=>{
		!report && getProfilesReport({ 
			...filters, from: startDate.format('YYYY-MM-DD'), until: endDate.format('YYYY-MM-DD') 
		})
			.then(res => setReport(res.data.data))
			.catch(error => handleError(error))
	}, [report, filters, startDate, endDate])

	if(!report) return <Spinner />

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className='float-right'>
						<Button color="primary" onClick={() => setShowExportModal(true)}>
							Exportar Visitas
						</Button>
					</div>
					<RangePicker
						ranges={{
							'Hoy': [moment(), moment()],
							'Esta semana': [moment().startOf('week'), moment().endOf('week')],
							'Este mes': [moment().startOf('month'), moment().endOf('month')],
							'Mes pasado': [moment().subtract(1,'months').startOf('month'), moment().subtract(1,'months').endOf('month')],
							'Este año': [moment().startOf('year'), moment().endOf('year')],
						}}
						placeholder={["F. de Inicio", "F. Final"]}
						value={[startDate, endDate]}
						onChange={ values => { 
							if(values.length > 0){
								setStartDate(values[0])
								setEndDate(values[1]) 
							}
						}}
					/>
				</CardBody>
			</Card>
			<ProfilesGroupsWidget report={report} />
			<Row>
				<Col sm="12">
					<ProfilesTopProductsWidget 
						report={report} 
						filters={filters}
						setFilters={setFilters}
						reload={() => setReport(null)}
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
					/>
				</Col>
			</Row>
			{ showExportModal && (
				<ExportVisitsModal 
					visible
					onClose={() => setShowExportModal(false)}
				/>
			)}
		</React.Fragment>
	)
}