import React, { useState, useEffect } from 'react'
import { Spinner, Card, CardBody } from 'reactstrap'
import { Result } from 'antd'
import styled from 'styled-components'

import QuoteDetails from './partials/QuoteDetails'
import encabezadoImg from '../../assets/images/encabezado_disposicion.jpg'
import encabezadoTempurImg from '../../assets/images/encabezado_tempur.png'
import encabezadoRosenImg from '../../assets/images/encabezado_rosen.png'
import metodosDePagoImg from '../../assets/images/metodos_de_pago.png'

import { getPublicQuote, storeQuoteOpen } from './services'
import { handleError } from '../../helpers'

export default function QuotePreview(props) {
	const [quote, setQuote] = useState(null)

	useEffect(()=>{
		const id = props.match.params.id

		!quote && getPublicQuote(id, { include: 'lead,store,user,publicNotes' })
			.then(res => {
				storeQuoteOpen({ quote_id: res.data.data.id })
					.then(()=>{
						setQuote(res.data.data)
					})
					.catch(error => handleError(error))
			})
			.catch(error => handleError(error))

	}, [quote, props])

	if(!quote) return <Spinner />

	return (
		<div className='quote-preview'>
			<img 
				src={quote.store.brand === 'TEMPUR' ? encabezadoTempurImg : quote.store.brand === 'ROSEN' ? encabezadoRosenImg : encabezadoImg}
				alt="Colchones Spring" 
				width="100%"
			/>
			{ !quote.voided_at ? (
				<React.Fragment>
					{ quote.published_at ? (
						<React.Fragment>
							<Card className='mb-0'>
								<CardBody className="px-4">
									<QuoteDetails quote={quote} />
								</CardBody>
							</Card>
							{ quote.store.channel === 'RETAIL' && (
								<PaymentMethodsDiv>
									<img src={metodosDePagoImg} alt="Metodos de pago"/>
								</PaymentMethodsDiv>
							)}
						</React.Fragment>
					) : (
						<Result status="warning" title="Esta cotización aún se encuentra en borrador" />
					)}
				</React.Fragment>
			) : (
				<Result status="warning" title="Esta cotización fue anulada" />
			)}	
		</div>
	)
}

const PaymentMethodsDiv = styled.div`
	background-color: #686868;
	padding: 20px;
	text-align: center;
	img {
		width: 100%;
		max-width: 650px;
	}
`