import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, CardBody, CardFooter, Button } from 'reactstrap'
import { message } from 'antd'

import AddProductModal from '../../Quoting/partials/AddProductModal'
import { EmptyCard } from '../../Quoting/partials/QuoteProductsDetails'
import ProductItemsList from '../../Quoting/partials/ProductItemsList'

import { assignProductToQuote } from '../services'
import { handleError } from '../../../helpers'

export default function AddOptionModal({ visible, onClose, reload, quote }) {
	const [items, setItems] = useState([])
	const [showAddItemModal, setShowAddItemModal] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleSubmit = async () => {
		setLoading(true)
		items.map(async (item, index) => {
			assignProductToQuote(quote.id, item.product.id, {
				group_index: quote.product_groups[quote.product_groups.length - 1].index + 1,
				quantity: item.quantity,
				gross_price: item.rate.gross_price,
				net_price: item.rate.net_price,
				discount: item.rate.discount,
				rate_code: item.rate.code,
				rate_desc: item.rate.description,
				before_edit_price: item.rate.before_edit_price,
			})
				.then(() => {
					if( index === items.length-1 ){
						message.success('Opción agregada exitosamente')
						reload()
						onClose()
					}
				})
				.catch(error => handleError(error))
		})
	}

	return (
		<Modal isOpen={visible} toggle={onClose} size="lg">
			<ModalHeader toggle={onClose}>Asociar Nueva Opción</ModalHeader>
			<ModalBody>
				<CardBody className="p-0">
					<p className="small mb-2 bold">Nueva opción</p>
					{ items.length > 0 ? (
						<React.Fragment>
							<ProductItemsList 
								items={items} 
								setItems={setItems} 
								handleAddItem={()=>setShowAddItemModal(true)}  
							/>							
						</React.Fragment>
					) : (
						<EmptyCard 
							handleAddItem={()=>setShowAddItemModal(true)}
						/>
					)}
				</CardBody>
				<CardFooter className="bg-white p-0 mt-2 pt-2">
					<Button 
						color="primary" 
						disabled={items.length === 0 || loading} 
						onClick={handleSubmit}
					>
						{!loading ? 'Agregar la Opción' : 'Guardando'}
					</Button>
				</CardFooter>
				{ showAddItemModal && (
					<AddProductModal
						visible
						handleClose={()=>setShowAddItemModal(false)}
						items={items}
						setItems={setItems}
						defaults={{ category: 'COLCHONES' }}
					/>
				)}
			</ModalBody>
		</Modal>
	)
}