import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Steps } from 'antd'

import ProductReferenceStep from './steps/ProductReferenceStep'
import ProductVariantStep from './steps/ProductVariantStep'
import ProductRateStep from './steps/ProductRateStep'

const { Step } = Steps

export default function AddProductModal({
	visible, handleClose, items, setItems, defaults
}) {
	const user = useSelector(state => state.auth.details)	
	const [currentStep, setCurrentStep] = useState(0)
	const [parentKey, setParentKey] = useState(defaults.parent_key)
	const [product, setProduct] = useState(null)
	const [rate, setRate] = useState(null)
	const [filters, setFilters] = useState({
		category: defaults.category,
		filterType: 'description',
		filterValue: ''
	})

	useEffect(()=>{
		const reset = () => {
			setProduct(null)
			setRate(null)
			setCurrentStep(0)
		}

		const handleSubmit = () => {
			setItems([
				...items,
				{product, rate, quantity: 1}
			])
			setParentKey(null)
			handleClose()
		}

		parentKey ? setCurrentStep(1) : reset()
		parentKey && product && setCurrentStep(2)
		parentKey && product && rate && handleSubmit()

	}, [parentKey, product, rate, items, setItems, setParentKey, handleClose])

	const onClose = () => {
		setParentKey(null)
		handleClose()
	}

	const contents = [
		<ProductReferenceStep
			storeId={user.current_store}
			filters={filters}
			setFilters={setFilters}
			setParentKey={setParentKey}
		/>,
		<ProductVariantStep
			storeId={user.current_store}
			parentKey={parentKey}
			handleStepBack={()=>setParentKey(null)}
			setProduct={setProduct}
		/>,
		<ProductRateStep
			storeId={user.current_store}
			productId={product ? product.id : null}
			handleStepBack={()=>setProduct(null)}
			setRate={setRate}
		/>
	]

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="xl">
				<ModalHeader toggle={onClose}>Agregar Producto</ModalHeader>
				<ModalBody className="p-5">
					<Steps progressDot current={currentStep}>
						<Step title="1. Referencia" />
						<Step title="2. Talla y Color" />
						<Step title="3. Tarifa" />
					</Steps>
					{ contents[currentStep] }
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}