import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap'
import { InputGroup, Form } from 'react-bootstrap'

import QuotesTable from './partials/QuotesTable'
import { getQuotes } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function QuotesList() {
	const user = useSelector(state => state.auth.details)
	const [quotes, setQuotes] = useState(null)
	const [filterType, setFilterType] = useState('number')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(()=>{
		!quotes && getQuotes({
			...pagination,
			'filter[active]': 1,
			[`filter[${filterType}]`]: filterValue,
			include: 'lead,user,store,opensCount,profile',
			sort: '-created_at',
			"filter[user_id]": user.id
		})
			.then(res => {
				setQuotes(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [quotes, filterType, filterValue, user, pagination])

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className="float-right mt-2">
						<InputGroup>
							<InputGroup.Prepend>
								<Form.Control 
									as="select" 
									className="bg-light"
									value={filterType}
									onChange={e => setFilterType(e.target.value)}
								>
									<option value="number">Buscar por número de cotización</option>
									<option value="lead.document">Buscar por cédula de cliente</option>
								</Form.Control>
							</InputGroup.Prepend>
							<Form.Control 
								placeholder="Escriba aquí" 
								value={filterValue}
								onChange={e => setFilterValue(e.target.value)}
							/>
							<InputGroup.Append>
								<Button color="primary" onClick={()=>setQuotes(null)}>Buscar</Button>
							</InputGroup.Append>
						</InputGroup>
					</div>
					<CardTitle>Seguimiento de Cotizaciones</CardTitle>
					<CardSubtitle className="mb-3">Consulte las cotizaciones asociadas a su usuario</CardSubtitle>
					<div className="table-responsive">
						<QuotesTable 
							quotes={quotes} 
							reload={()=>setQuotes(null)} 
							pagination={pagination}
							setPagination={setPagination}
						/>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}