import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import EmployeeSalesDashboard from './EmployeeSalesDashboard'

export default function EmployeeSales() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/venta_empleados" component={EmployeeSalesDashboard} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}