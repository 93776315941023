import React, { useState, useEffect } from 'react'
import { Button, Spinner } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { DatePicker, Divider, Empty, message, Select } from 'antd'
import moment from 'moment'

import 'moment/locale/es'
import locale from 'antd/es/date-picker/locale/es_ES'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { deliveryEstimate, getProductPromises, searchCities } from '../services'
import { handleError } from '../../../helpers'

export default function SimulatorForm({ setReport }) {
	const [selectedCity, setSelectedCity] = useState([])
	const [purchaseDate, setPurchaseDate] = useState(null)
	const [clientDate, setClientDate] = useState('')
	const [selectedProducts, setSelectedProducts] = useState(null)
	const [productPromises, setProductPromises] = useState(null)
	const [sizeType, setSizeType] = useState('regular_size')

	useEffect(() => {
		!productPromises && getProductPromises({})
			.then(res => setProductPromises(res.data.data))
			.catch(error => handleError(error))
	}, [ productPromises ])

	const onSubmit = () => {
		deliveryEstimate({
			city_route_id: selectedCity.value,
			purchase_date: purchaseDate.format('YYYY-MM-DD'),
			client_date: clientDate && clientDate.format('YYYY-MM-DD'),
			product_promises_ids: selectedProducts.join(','),
			product_size_type: sizeType
		})
			.then(res => {
				message.success('Estimación generada exitosamente')
				setReport(res.data)
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Ciudad de Entrega (destino) <span className="text-primary">*</span></Form.Label>
				<ServerSideSelect
					value={selectedCity}
					placeholder="Seleccione una ciudad"
					fetchOptions={searchCities}
					onChange={value => setSelectedCity(value)}
					className="full-width"
					notFoundContent={<Empty description="Población sin cobertura" imageStyle={{ height: 70 }} />}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Fecha de Compra <span className="text-primary">*</span></Form.Label>
				<DatePicker
					className="block full-width" 
					value={purchaseDate}
					disabledDate={current => current && current < moment().endOf('day')}
					onChange={date => setPurchaseDate(date)} 
					locale={locale}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Fecha Necesidad Cliente <small>(Opcional)</small></Form.Label>
				<DatePicker 
					className="block full-width"
					value={clientDate}
					disabled={!purchaseDate}
					disabledDate={current => current && purchaseDate && current < purchaseDate.endOf('day')}
					onChange={date => setClientDate(date)} 
					locale={locale}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Productos Despachados <span className="text-primary">*</span></Form.Label>
				{ productPromises ? (
					<Select
						mode="multiple"
						placeholder="Selecione los productos a enviar"
						onChange={value => setSelectedProducts(value)}
						className="full-width"
						filterOption={(inputValue, option) => option.keyword.toLowerCase().includes(inputValue.toLowerCase())}
					>
						{ productPromises.map( p => (
							<Select.Option key={p.id} value={p.id} keyword={p.keyword}>
								{p.keyword}
							</Select.Option> 
						))}
					</Select>
				) : <Spinner size="sm" />}
			</Form.Group>
			<Form.Group>
				<Form.Label>Medidas Especiales</Form.Label>
				<Form.Control
					as="select"
					value={sizeType}
					onChange={e => setSizeType(e.target.value)}
				>
					<option value="regular_size">Ninguno con medida especial</option>
					<option value="custom_size">Uno o más con medida especial</option>
				</Form.Control>
			</Form.Group>
			<Button 
				color="primary" 
				onClick={onSubmit}
				disabled={(selectedCity.value && purchaseDate && selectedProducts) ? false : true}
			>
				Estimar Fecha de Entrega
			</Button>
			<Divider className="my-2" />
			<Button 
				outline
				onClick={() => {
					setReport(null)
					setProductPromises(null)
					setSelectedCity([])
					setSelectedProducts(null)
					setPurchaseDate(null)
					setClientDate('')
					setSizeType('regular_size')
				}}
			>
				Comenzar de Nuevo
			</Button>
		</React.Fragment>
	)
}