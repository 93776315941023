import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Card, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { message, Spin } from 'antd'

import { getLeads, storeLead, updateLead, validateEmail } from '../../Leads/services'
import { handleError } from '../../../helpers'

export default function QuoteLeadDetails({ isSubmited, setIsSubmited, leadId, setLeadId }) {
	const [lead, setLead] = useState(null)
	const [mobile, setMobile] = useState('')
	const { register, errors, trigger, getValues, watch } = useForm()
	const [loading, setLoading] = useState(false)
	const [loadingLead, setLoadingLead] = useState(false)
	const [loadingEmail, setLoadingEmail] = useState(false)

	useEffect(()=>{
		if(!isSubmited && loading) setLoading(false)
	}, [loading, isSubmited])

	useEffect(()=>{

		const onSubmit = async () => {
			const isFormValid = await trigger()
			
			if (!lead || !isFormValid) {
				message.warning('No ha ingresado un contacto válido.')
				setIsSubmited(false)
			} else {
				setLoading(true)
	
				const values = getValues()
				if(lead.mobile !== mobile) values.mobile = mobile
	
				if(!lead.id){
					storeLead(values)
						.then(res => setLeadId(res.data.data.id))
						.catch(error => {
							handleError(error)
							setIsSubmited(false)
						})
				} else {
					updateLead(lead.id, values)
						.then(()=>setLeadId(lead.id))
						.catch(error => {
							handleError(error)
							setIsSubmited(false)
						})
				}
			}		
		}

		isSubmited && !leadId && !loading && onSubmit()

	}, [leadId, loading, mobile, getValues, lead, setIsSubmited, isSubmited, setLeadId, trigger])

	const onSearch = () => {
		setLoadingLead(true)
		lead && setLead(null)
		mobile && getLeads({ "filter[mobile]": mobile, sort: '-created_at' })
			.then(res => setLead( res.data.data.length > 0 ? res.data.data[0] : {}))
			.catch(handleError)
			.finally(()=>setLoadingLead(false))
	}		

	const handleEmailValidation = () => {
		setLoadingEmail(true)
		validateEmail(watch('email'))
			.then(res => res.data.data.is_valid ? message.success(res.data.message) : message.warning(res.data.message))
			.catch(handleError)
			.finally(() => setLoadingEmail(false))
	}

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Datos de Contacto</CardTitle>
					<CardSubtitle className="mb-3">¿Para quién es esta cotización?</CardSubtitle>
					<div className="bg-light p-3">
						<Spin spinning={loading}>
							<Form.Group>
								<Form.Label>Celular del Contacto <span className="text-primary">*</span></Form.Label>
								<InputGroup>
									<Form.Control
										placeholder="Escriba solo números"
										value={mobile}
										onChange={e => setMobile(e.target.value)}
										onKeyDown={e => e.key === 'Enter' && onSearch()}
										type="number"
									/>
									<Button 
										color="primary" 
										outline 
										onClick={onSearch}
										disabled={loadingLead}
									>
										Consultar
									</Button>
								</InputGroup>
							</Form.Group>
							{ lead && (
								<Form>													
									<Form.Group>
										<Form.Label>Nombre Completo <span className="text-primary">*</span></Form.Label>
										<Form.Control
											name="name"
											placeholder="Escriba el nombre completo"
											defaultValue={lead.name}
											ref={register({ required:true })}
										/>
										{ errors.name && <Form.Text className="text-warning">Ingrese el nombre completo</Form.Text> }
									</Form.Group>
									<Form.Group>
										<Form.Label>Cédula del Contacto <small>(opcional)</small></Form.Label>
										<Form.Control
											name="document"
											placeholder="Escriba la cédula"
											type="number"
											defaultValue={lead.document}
											ref={register({})}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>Correo Electrónico <small>(opcional)</small></Form.Label>
										<InputGroup>
											<Form.Control
												name="email"
												type="email"
												placeholder="Escriba un correo válido"
												defaultValue={lead.email}
												ref={register({})}
												onKeyDown={e => e.key === 'Enter' && handleEmailValidation()}
											/>
											<Button 
												color="primary" 
												outline 
												onClick={handleEmailValidation}
												disabled={loadingEmail}
											>
												Validar
											</Button>
										</InputGroup>
									</Form.Group>
									<Form.Group>
										<Form.Label>Teléfono Secundario <small>(opcional)</small></Form.Label>
										<Form.Control
											name="phone"
											placeholder="Escriba un teléfono válido"
											type="number"
											defaultValue={lead.phone}
											ref={register({})}
										/>
									</Form.Group>
									<p className="text-center text-muted small"><em>Al cotizar en Spring el cliente acepta la politica de tratamiento de datos</em></p>
								</Form>
							)}
						</Spin>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}