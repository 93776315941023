import React from 'react'
import { Button, Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import { Table, Divider } from 'antd'
import { Form } from 'react-bootstrap'

import { currency, round } from '../../../helpers'

export default function ProfilesTopProductsWidget({ report, filters, setFilters, reload }) {

	const columns = [
		{
			title: 'Producto',
			dataIndex: 'name',
		},
		{
			title: 'Talla',
			dataIndex: 'size',
		},
		{
			title: 'Color',
			dataIndex: 'color',
		},
		{
			title: 'Unidades Cotizadas',
			dataIndex: 'quantity',
			sorter: (a, b) => a.quantity - b.quantity,
			render: t => currency(t)
		},
		{
			title: 'Valor en Contizaciones',
			dataIndex: 'net_price',
			render: t => `$ ${currency(round(parseInt(t)))}`,
		},
		// {
		// 	title: 'Ventas (Todas las tallas)',
		// 	dataIndex: 'sales_quantity',
		// 	sorter: (a, b) => a.sales_quantity - b.sales_quantity,
		// 	render: (t, r) => `${currency(t)} (${round((parseInt(t)/parseInt(r.quantity))*100, 0)}%)`
		// },
		// {
		// 	title: 'Ventas (Todas las tallas)',
		// 	dataIndex: 'sales_value',
		// 	render: t => `$ ${currency(round(parseInt(t)))}`,
		// }
	]

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<Row>
						<Col>
							<Form.Control 
								as="select"
								value={filters.gender}
								onChange={e => setFilters({ ...filters, gender: e.target.value })}
							>
								<option value="">:: Ambos Generos ::</option>
								<option value="male">Solo Hombres</option>
								<option value="female">Solo Mujeres</option>
							</Form.Control>
						</Col>
						<Col>
							<Form.Control 
								as="select"
								value={filters.age_range}
								onChange={e => setFilters({ ...filters, age_range: e.target.value })}
							>
								<option value="">:: Todos los rangos de edad ::</option>
								<option>Menor de 20 años</option>
								<option>21 a 30 años</option>
								<option>31 a 40 años</option>
								<option>41 a 60 años</option>
								<option>Mayor de 61 años</option>
							</Form.Control>
						</Col>
						<Col>
							<Form.Control 
								as="select"
								value={filters.group_count}
								onChange={e => setFilters({ ...filters, group_count: e.target.value })}
							>
								<option value="">:: Todos los # de acompañantes ::</option>
								<option value="0">Sin acompañantes</option>
								<option value="1">1 acompañante</option>
								<option value="2">2 acompañantes</option>
								<option value="3">3 acompañantes</option>
								<option value="4">4 acompañantes</option>
								<option value="5">5 acompañantes</option>
								<option value="6">6 acompañantes</option>
							</Form.Control>
						</Col>
						<Col>
							<Button color="primary" className="full-width" onClick={reload}>Buscar</Button>
						</Col>
					</Row>
					<Divider className="mt-2 mb-3" />
					{ report ? (
						<React.Fragment>
							<Table 
								dataSource={report.products}
								columns={columns}
								rowKey={r => r.id}
								size="small"
							/>
						</React.Fragment>
					) : <Spinner size="sm" />}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}