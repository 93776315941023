import React, { useState, useEffect } from 'react'
import { Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Empty, Divider } from 'antd'

import { ReferenceBox, ResultsBox } from './ProductReferenceStep'
import { syncAndGetProducts } from '../../../Products/services'
import { handleError } from '../../../../helpers'

export default function ProductVariantStep({ storeId, parentKey, handleStepBack, setProduct }) {
	const [products, setProducts] = useState(null)

	useEffect(()=>{
		!products && parentKey && syncAndGetProducts(storeId, parentKey)
			.then(res => setProducts(res.data.data))
			.catch(error => handleError(error))
	}, [products, storeId, parentKey])

	return (
		<React.Fragment>
			<ResultsBox className="bg-light mt-3">
				{ products ? (
					<React.Fragment>
						{ products.length > 0 ? (
							<React.Fragment>
								{ products.map( (product, i) => (
									<ReferenceBox key={i} className="text-link text-dark" onClick={()=>setProduct(product)}>
										<div className="float-right pt-1">
											<Button color="primary" outline>Seleccionar</Button>
										</div>
										<h6 className="mb-0">
											{product.size}
											<Divider type="vertical" />
											{product.color}
										</h6>
										<p className="mb-0 text-muted">{product.description} | {product.code}</p>
									</ReferenceBox>
								)) }
							</React.Fragment>
						) : <Empty description="No se encontraron productos" />}
					</React.Fragment>
				) : <Spinner size="sm" />}
			</ResultsBox>
			<div className="mt-2 mb-0">
				<Button color="primary" outline onClick={handleStepBack}>
					<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
					Volver atrás
				</Button>
			</div>
		</React.Fragment>
	)
}