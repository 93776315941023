import { useEffect } from 'react'

import { createSocketConnection } from './springSocketService'

export const useSocket = ({ type, callBack }) => {
	useEffect(() => {
		createSocketConnection()

		switch (type) {
			case 'QUOTE_OPENED': {
				return listen(callBack, `quote.opens`, '.quote.opened')
			}

			default: return null
		}
	});
};

function listen(callBack, channel, event) {
	window.Echo.channel(channel).listen(event, payload => {
		callBack(payload)
	})

	return () => window.Echo.leaveChannel(channel)
}