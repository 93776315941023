import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Divider } from 'antd'

import PushNotificationsWidget from './partials/PushNotificationsWidget'
import QuoteOpensWidget from './partials/QuoteOpensWidget'
import MainMenu from './partials/MainMenu'

import LogoSpringLight from '../assets/images/logo_spring_light.png'
import LogoTempurLight from '../assets/images/logo_tempur_light.png'
import LogoRosenLight from '../assets/images/logo_rosen_light.png'

export default function MainLayout({ children }) {
	const [openMenu, setOpenMenu] = useState(false)
	const user = useSelector(state => state.auth.details)
	const brand = window.location.host.split('.')[1]
	const theme = brand === 'rosen' ? 'dark' : 'light'

	return (
		<Container theme={theme}>
			<TopBar className="no-print" brand={brand}>
				{ brand === 'rosen' ? (
					<React.Fragment>
						<img src={LogoRosenLight} alt="Logo Rosen" width="120px" className="mr-3" />
						<img src={LogoTempurLight} alt="Logo Tempur" width="100px" className="mr-4" />
					</React.Fragment>
				) : (
					<img src={LogoSpringLight} alt="Logo Spring" width="100px" className="mr-3" />
				)}
				<FontAwesomeIcon 
					icon={ openMenu ? faTimes : faBars}
					onClick={()=>setOpenMenu(!openMenu)}
					className="nav-toggle-icon"
				/>
				<div className="float-right mt-2 pt-1 hide-sm">
					<QuoteOpensWidget />
					<Divider type='vertical' style={{ borderColor: 'white' }} />
					<PushNotificationsWidget />
					<Divider type='vertical' style={{ borderColor: 'white' }} />
					<span>{user.name}</span>
				</div>
			</TopBar>
			{ openMenu && (
				<MainMenu closeMenu={()=>setOpenMenu(false)} />
			)}
			<MainContent theme={theme}>
				{children}
			</MainContent>
			<Footer theme={theme}>
				<p>Cotizador desarrollado por{" "}
					<a href="https://smart4.com.co" target="_blank" rel="noreferrer">
						Smart4 Solutions
					</a>
					{" | "}
					Versión {process.env.REACT_APP_VERSION}
				</p>
			</Footer>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: ${props => props.theme === 'dark' ? '#303c41' : '#f7f8f9'};
	& .card-body, .card-header, .card {
		background-color: ${props => props.theme === 'dark' ? '#38484E' : '#ffffff'};
		color: ${props => props.theme === 'dark' ? '#ffffff' : '#333333'};
	}
	& .card-title {
		color: ${props => props.theme === 'dark' ? '#ffffff' : '#333333'};
	}
	& .bg-light, .bg-white {
		background-color: ${props => props.theme === 'dark' ? '#637075!important' : '#ffffff'};
		color: ${props => props.theme === 'dark' ? '#ffffff' : '#333333'};
	}
	& .text-muted {
		color: ${props => props.theme === 'dark' ? '#ffffff!important' : '#949aa2'};
	}
	& .ant-list-item {
		background-color: #fff;
	}
	& .quote-preview .bg-light, .quote-preview .bg-white, .quote-preview .card-body, .quote-preview .card-title {
		background-color: #ffffff !important;
		color: #333333;
	}
	& .ant-timeline-item-content {
		color: ${props => props.theme === 'dark' ? '#fff' : '#333'};
	}
`

const TopBar = styled.div`
	width: 100%;
	padding: 5px 30px;
	background-color: ${props => props.brand === 'rosen' ? '#38484E' : '#414548'};
	color: #fff;
	position: fixed;
	top: 0px;
	z-index: 10;
`

const MainContent = styled.div`
	padding: 30px;
	width: 100%;
	min-height: 90vh;
	position:relative;
	top: 52px;
`

const Footer = styled.div`
	padding: 30px 20px 20px;
	width: 100%;
	text-align: center;
	color: ${props => props.theme === 'dark' ? '#fff' : '#333'};
`