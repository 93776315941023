import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import StockAlertForm from './StockAlertForm'

import { storeStockAlert } from '../services'
import { handleError } from '../../../helpers'

export default function NewStockAlertForm({ reload }) {
	const user = useSelector(state => state.auth.details)
	const { handleSubmit, register, errors, reset } = useForm()
	const [selectedProduct, setSelectedProduct] = useState([])

	const onSubmit = values => {
		storeStockAlert({
			...values,
			store_id: user.current_store,
			user_id: user.id,
			product_id: selectedProduct.value
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				reset()
				setSelectedProduct([])
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<StockAlertForm 
					register={register}
					errors={errors}
					selectedProduct={selectedProduct}
					setSelectedProduct={setSelectedProduct}
				/>
				<Button color="primary">Agregar Alerta Nueva</Button>
			</Form>
		</React.Fragment>
	)
}