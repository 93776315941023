import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Spinner, Button } from 'reactstrap'
import { Select, message } from 'antd'

import { userNewStore } from '../actions'
import { getStores } from '../../Stores/services'
import { updateUser } from '../services'
import { handleError } from '../../../helpers'

const { Option } = Select;

export default function ChangeStoreModal({ visible, onClose, user, reload }) {
	const auth = useSelector(state => state.auth)
	const [stores, setStores] = useState(null)
	const [storeSelected, setStoreSelected] = useState(user.current_store)
	const dispatch = useDispatch()

	useEffect(()=>{
		!stores && visible && getStores({
			'filter[related_to_user]': auth.details.id
		})
			.then(res => setStores(res.data.data))
			.catch(error => handleError(error))
	}, [stores, visible, auth])

	const handleSubmit = () => {
		updateUser(user.id, { current_store: storeSelected })
			.then(res => {
				dispatch(userNewStore(res.data.data))
				message.success('Tienda actualizada exitosamente')
				onClose()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} centered>
				<ModalHeader toggle={onClose}>Cambiar Tienda</ModalHeader>
				<ModalBody>
					<p>Eliga la tienda en la que desea cotizar:</p>
					{ stores ? (
						<React.Fragment>
							<Select
								showSearch
								style={{ width: '100%' }}
								placeholder="Seleccione una tienda"
								optionFilterProp="children"
								value={storeSelected}
								onChange={value => setStoreSelected(value)}
								filterOption={(input, option) =>
									option.children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{ stores.map(store => <Option key={store.id} value={store.id}>{store.code} {store.name}</Option>) }
							</Select>
							<Button color="primary" className="full-width mt-2" onClick={handleSubmit}>Cambiar de Tienda Principal</Button>
						</React.Fragment>
					) : <Spinner size="sm" />}
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}