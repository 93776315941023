import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { Badge } from 'reactstrap'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons'

import Pagination from '../../../components/Pagination'
import { updateStockAlert } from '../services'
import { handleError } from '../../../helpers'
import EditStockAlertModal from './EditStockAlertModal'


export default function StockAlertsTable({ stockAlerts, reload, pagination, setPagination }) {
	const [editStockAlert, setEditStockAlert] = useState(null)
	
	const columns = [
		{
			title: 'Fecha',
			dataIndex: 'updated_at',
			render: t => moment(t).format('YYYY-MM-DD HH:mm')
		},
		{
			title: 'Producto',
			dataIndex: 'product',
			render: t => `${t.name} (${t.color} - ${t.size})`
		},
		{
			title: 'Unidades',
			dataIndex: 'units',
		},
		{
			title: 'Estado',
			dataIndex: 'solved_at',
			render: (t, r) => t ? (
				<Badge color="success">Resuelta</Badge>
			) : r.recived_at ? (
				<Badge color="info">Recibida</Badge>
			) : (
				<Badge color="dark">Pendiente</Badge>
			)
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon icon={faEdit} className='text-link' onClick={() => setEditStockAlert(r)} />
					</Tooltip>
					{ !r.solved_at && (
						<React.Fragment>
							<Divider type='vertical' />
							<Tooltip title="Resuelta">
								<Popconfirm
									title="¿Desea marcar como resuelta la alerta?"
									okText="Resuelto"
									cancelText="Cancelar"
									onConfirm={() => handleSolved(r.id)}
								>
									<FontAwesomeIcon icon={faCheck} className='text-link' />
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
					<Divider type='vertical' />
					{/* <Tooltip title="Eliminar">
						<Popconfirm
							title="¿Desea eliminar la alerta?"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon icon={faTrash} className='text-link' />
						</Popconfirm>
					</Tooltip> */}
				</React.Fragment>
			)
		}
	]

	// const handleDelete = id => {
	// 	deleteStockAlert(id)
	// 		.then(res => {
	// 			message.success(res.data.message)
	// 			reload()
	// 		})
	// 		.catch(error => handleError(error))
	// }

	const handleSolved = id => {
		updateStockAlert(id, { solved_at: moment().format('YYYY-MM-DD HH:mm') })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={stockAlerts}
				columns={columns}
				pagination={false}
				loading={!stockAlerts}
				expandable={{ expandedRowRender: record => (
					<p className='mb-0'><strong>Observaciones:</strong> {record.observations}</p> 
				)}}
				locale={{ emptyText: <Empty description="No tiene alertas creadas" /> }}
				size="small"
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ editStockAlert && (
				<EditStockAlertModal 
					visible
					onClose={() => setEditStockAlert(null)}
					reload={reload}
					stockAlert={editStockAlert}
				/>
			)}
		</React.Fragment>
	)
}