import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap'
import { InputGroup, Form } from 'react-bootstrap'

import LeadsTable from './partials/LeadsTable'
import { getLeads } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function LeadsList() {
	const user = useSelector(state => state.auth.details)
	const [leads, setLeads] = useState(null)
	const [type, setType] = useState('name')
	const [value, setValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(()=>{
		!leads && getLeads({ 
			...pagination,
			"filter[by_user_id]": user.id, 
			[`filter[${type}]`]: value,
			include: 'quotesCount',
			sort: '-updated_at',
		})
			.then(res => {
				setLeads(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	})

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className="float-right mt-2">
						<InputGroup>
							<InputGroup.Prepend>
								<Form.Control 
									as="select" 
									className="bg-light"
									value={type}
									onChange={e => setType(e.target.value)}
								>
									<option value="name">Buscar por nombre</option>
									<option value="document">Buscar por cédula</option>
									<option value="email">Buscar por correo</option>
									<option value="mobile">Buscar por celular</option>
								</Form.Control>
							</InputGroup.Prepend>
							<Form.Control 
								placeholder="Escriba aquí" 
								value={value}
								onChange={e => setValue(e.target.value)}
							/>
							<InputGroup.Append>
								<Button color="primary" onClick={()=>setLeads(null)}>Buscar</Button>
							</InputGroup.Append>
						</InputGroup>
					</div>
					<CardTitle>Directorio de Contactos</CardTitle>
					<CardSubtitle className="mb-3">Listado de prospectos asociados a su usuario.</CardSubtitle>
					<LeadsTable 
						leads={leads}
						reload={() => setLeads(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}