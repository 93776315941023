import React from 'react'
import { Drawer } from 'antd'

export default function GenerationInsights({ visible, onClose, slug }) {
	
	const generations = {
		male: {
			title: 'Hombre',
			subtitle: 'Proveedor de Recurso Pensado',
			items: [
				<span>Están más pendientes del <span className="text-primary">precio</span> y de los beneficios extra</span>,
				<span>Buscan información visual <span className="text-primary">concreta</span></span>,
				<span>Esperan la <span className="text-primary">decisión de su pareja</span> para no cometer errores</span>,
				<span>Prestan más atención a los <span className="text-primary">medios de pago y facilidad de crédito</span></span>,
				<span>Esperan un <span className="text-primary">asesor experto</span> que lo guie en el proceso</span>,
				<span><span className="text-primary">Comparan entre precios</span> más que entre beneficios del producto</span>,
			]
		},
		female: {
			title: 'Mujer',
			subtitle: 'Proveedora de Bienestar',
			items: [
				<span>Prestan más atención a los <span className="text-primary">detalles</span></span>,
				<span>Se preocupan por <span className="text-primary">los beneficios</span> para toda su familia</span>,
				<span>Buscan más información para <span className="text-primary">elegir bien</span></span>,
				<span>Prestan más atención a la <span className="text-primary">calidad, la durabilidad y garantía</span></span>,
				<span>Responden a <span className="text-primary">relaciones más empáticas</span> con el asesor</span>,
				<span>Compran a prueba de <span className="text-primary">niños</span> (antifluidos, control de olores, etc.)</span>,
				<span>Le <span className="text-primary">consultan a la familia</span> buscando respaldar su decisión</span>,
			]
		},
	}
	
	return (
		<React.Fragment>
			<Drawer
				visible={visible}
				onClose={onClose}
				width={400}
				title={slug && generations[slug].title}
			>
				<h6 className="mb-3">{slug && generations[slug].subtitle}</h6>
				<ul className="pl-3">
					{slug && generations[slug].items.map( (item, i) => <li className="mb-2" key={i}>{item}</li> )}
				</ul>
			</Drawer>
		</React.Fragment>
	)
}