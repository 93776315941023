import React, { useState } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { faChartBar, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import BestSellersDrawer from '../../SpringAI/partials/BestSellersDrawer'
import MostQuotedDrawer from '../../SpringAI/partials/MostQuotedDrawer'

export default function RankingDrawersCard() {
	const [showBestSellersDrawer, setShowBestSellersDrawer] = useState(false)
	const [showMostQuotedDrawer, setShowMostQuotedDrawer] = useState(false)

	return (
		<React.Fragment>
			<Card>
				<CardBody className="p-1">
					<Row noGutters={true} className="mt-1">
						<Col className="mb-1">
							<CategoryCard onClick={()=>setShowBestSellersDrawer(true)}>
								<h6><FontAwesomeIcon icon={faMoneyBill} /> Productos más vendidos</h6>
							</CategoryCard>
						</Col>
						<Col className="mb-1">
							<CategoryCard onClick={()=>setShowMostQuotedDrawer(true)}>
								<h6><FontAwesomeIcon icon={faChartBar} /> Productos más cotizados</h6>
							</CategoryCard>
						</Col>
					</Row>
				</CardBody>
			</Card>

			<BestSellersDrawer 
				visible={showBestSellersDrawer}
				onClose={() => setShowBestSellersDrawer(false)}
			/>
			<MostQuotedDrawer 
				visible={showMostQuotedDrawer}
				onClose={() => setShowMostQuotedDrawer(false)}
			/>
		</React.Fragment>
	)
}

const CategoryCard = styled.div`
	text-align: center;
	background-color: #f8f9fa;
	cursor: pointer;
	padding: 10px 10px;
	margin: 0px 5px;
	&:hover {
		background-color: #3b4351;
		color: #fff;
	}
	&:hover h6 {
		color: #fff;		
	}
	& h6 {
		margin-bottom: 0px;
		font-size: 16px;
	}
	& svg {
		margin-right: 5px;
	}
`