import React from 'react'
import { useSelector } from 'react-redux' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'

import addNotification from 'react-push-notification'
import { Notifications } from 'react-push-notification'

import { useSocket } from '../../sockets/springSocketHook'

export default function PushNotificationsWidget() {
	const user = useSelector(state => state.auth.details)

	useSocket({
		type: 'QUOTE_OPENED',
		callBack: (payload) => {
			if( payload.store_id === user.current_store ){
				addNotification({
					title: payload.message,
					native: true,
					theme: 'darkblue',
					duration: 15000,
					silent: false
				})
			}
		}
	})

	const handleTest = () => {
		addNotification({
			title: 'Prueba de notificaciones en su dispositivo',
			native: true
		})
	}

	return (
		<React.Fragment>
			<Notifications position="top-right" />

			<div 
				className='inline-block' 
				style={{ cursor:'pointer' }}
				onClick={handleTest}
			>
				<FontAwesomeIcon icon={faBell} />{" "}
				Probar Notificaciones
			</div>
		</React.Fragment>
	)
}