import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

import { userLogout } from './actions'
import { logout } from './services'

export default function Logout() {
	const auth = useSelector(state => state.auth)
	const dispatch = useDispatch()

	useEffect(() => {
		logout()
			.finally(() => dispatch(userLogout()))
	})

	if(!auth.token) return <Redirect to="/" />

	return (
		<React.Fragment>
			<Spinner />
		</React.Fragment>
	)
}