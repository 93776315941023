import React, { useState, useEffect } from 'react'
import { Button, Spinner } from 'reactstrap'
import { InputGroup, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { Empty } from 'antd'

import { getProducts } from '../../../Products/services'
import { handleError } from '../../../../helpers'

export default function ProductReferenceStep({ storeId, filters, setFilters, setParentKey }) {
	const [references, setReferences] = useState(null)
	const [meta, setMeta] = useState({ total: 0 })
	const brand = window.location.host.split('.')[1]

	useEffect(()=>{
		!references && getProducts({
			per_page: 100,
			"filter[distinct_references]": true,
			"filter[active]": true,
			"filter[category]": filters.category,
			[`filter[${filters.filterType}]`]: filters.filterValue,
			"filter[with_store_rates]": brand === 'rosen' ? storeId : null,
		})
			.then(res => {
				setReferences(res.data.data)
				setMeta(res.data.meta)
			})
			.catch(error => handleError(error))
	}, [ references, storeId, filters, brand ])

	return (
		<React.Fragment>
			<div className="bg-white mt-3 mb-2">
				<InputGroup className="full-width">
					<InputGroup.Prepend>
						<Form.Control 
							as="select" 
							className="mr-2"
							value={filters.category}
							onChange={e => setFilters({...filters, category: e.target.value})}
						>
							<option value="">::Todas las categorías::</option>
							<option>COLCHONES</option>
							<option>LENCERIA</option>
							<option>ALMOHADAS</option>
							<option>MUEBLES</option>
							<option>BASES Y PATAS</option>
							<option>CAMAS</option>
							<option>ACCESORIOS</option>
							<option>COMBO</option>
							<option>BONOS</option>
							<option value="CONCEPTOS ADMINISTRATIVOS">OTROS</option>
						</Form.Control>
					</InputGroup.Prepend>					
					<InputGroup.Prepend>
						<Form.Control 
							as="select" 
							className="bg-light"
							value={filters.filterType}
							onChange={e => setFilters({...filters, filterType: e.target.value})}
						>
							<option value="description">Buscar por nombre</option>
							<option value="code">Buscar por código</option>
							<option value="reference">Buscar por referencia</option>
						</Form.Control>
					</InputGroup.Prepend>
					<Form.Control 
						placeholder="Escriba aquí..." 
						value={filters.filterValue}
						onChange={e => setFilters({...filters, filterValue: e.target.value})}
						onKeyDown={e => e.key === 'Enter' && setReferences(null)}
					/>
					<InputGroup.Append>
						<Button color="primary" onClick={()=>setReferences(null)}>
							Buscar
						</Button>
					</InputGroup.Append>
				</InputGroup>
			</div>
			<ResultsBox className="bg-light">
				{ references ? (
					<React.Fragment>
						{ references.length > 0 ? (
							<React.Fragment>
								{ references.map( (reference, i) => (
									<ReferenceBox key={i} className="text-link text-dark" onClick={()=>setParentKey(reference.parent_key)}>
										<div className="float-right pt-1">
											<Button color="primary" outline>Seleccionar</Button>
										</div>
										<h6 className="mb-0">{reference.description}</h6>
										<p className="mb-0 text-muted">{reference.section} | {reference.reference}</p>
									</ReferenceBox>
								)) }
								<p className="text-center text-muted small">Mostrando {references.length} resultados de {meta.total} encontrados</p>
							</React.Fragment>
						) : <Empty description="No se encontraron productos" />}
					</React.Fragment>
				) : <Spinner size="sm" />}
			</ResultsBox>
			<div className="mt-3 text-center">
				<p className="text-muted mb-0"><em>Eliga una referencia de producto</em></p>
			</div>
		</React.Fragment>
	)
}

export const ResultsBox = styled.div`
	padding: 0px 20px;
	height: 300px;
	overflow-y: scroll;
	border-top: 20px solid #f8f9fa;
	border-bottom: 20px solid #f8f9fa;	
`

export const ReferenceBox = styled.div`
	padding: 10px 15px;
	background-color: #fff;
	margin-bottom: 10px;
	border: 1px solid #f8f8f8;
	&:hover h6 {
		color: #e42537;
	}
`