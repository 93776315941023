import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, CardSubtitle, Button, Spinner } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message, Spin } from 'antd'
import moment from 'moment'

import ChangeStoreModal from '../../Authentication/partials/ChangeStoreModal'
import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchUsers } from '../../Users/services'

export default function QuoteExtraDetails({ 
	store, 
	expiredAt, 
	setExpiredAt, 
	isSubmited, 
	setIsSubmited, 
	comment, 
	setComment,
	extraUserSelected,
	setExtraUserSelected
}) {
	const user = useSelector(state => state.auth.details)
	const [changeStore, setChangeStore] = useState(false)

	const handleExpiredAt = value => {
		const newValue = moment(value)

		if(moment().add(6, 'days').isBefore(newValue))
			return message.warning('La fecha de vencimiento máxima es: ' + moment().add(6,'days').format('YYYY-MM-DD'))

		if(moment().endOf('month').isBefore(newValue))
			return message.warning('La fecha de vencimiento máxima es: ' + moment().endOf('month').format('YYYY-MM-DD'))
		
		if(moment().diff(newValue, 'days') > 0)
			return message.warning('La fecha de vencimiento debe ser posterior a hoy')

		setExpiredAt(value)
	}

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Finalizar Cotización</CardTitle>
					<CardSubtitle className="mb-3">Completar y enviar cotización</CardSubtitle>
					<div className="bg-light p-3">
						<Spin spinning={isSubmited}>
							<Form.Group>
								<Form.Control value={`${store.code} - ${store.name}`} disabled />
								<Form.Text className="text-link" onClick={()=>setChangeStore(true)}>Cambiar Tienda</Form.Text>
							</Form.Group>
							<Form.Group>
								<Form.Label>Fecha de Vencimiento (Día/Mes/Año)</Form.Label>
								<Form.Control 
									type="date"
									value={expiredAt}
									onChange={e => handleExpiredAt(e.target.value)}
								/>
								<Form.Text>Fecha Máxima: {moment().endOf('month').format('YYYY-MM-DD')}</Form.Text>
							</Form.Group>
							<Form.Group>
								<Form.Label>Comentario <small>(Opcional)</small></Form.Label>
								<Form.Control 
									as="textarea"
									value={comment}
									placeholder="Solo será visible para el asesor"
									onChange={e => setComment(e.target.value)}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Asesor de canal o tienda asociado <small>(opcional)</small></Form.Label>
								<ServerSideSelect
									value={extraUserSelected}
									placeholder="Seleccione un usuario"
									fetchOptions={searchUsers}
									onChange={value => setExtraUserSelected(value)}
									className="full-width"
								/>
							</Form.Group>
						</Spin>
						<div className="mt-4 text-right">
							<Button 
								color="primary" 
								disabled={isSubmited}
								onClick={()=>setIsSubmited(true)}
							>
								{ isSubmited && <Spinner size="sm" className="mr-2" /> }
								Generar Cotización
							</Button>
						</div>
					</div>
				</CardBody>
			</Card>
			<ChangeStoreModal
				visible={changeStore}
				onClose={()=>setChangeStore(false)}
				user={user}
				reload={()=>window.location.reload()}
			/>
		</React.Fragment>
	)
}