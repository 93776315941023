import React from 'react'
import { List } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/free-solid-svg-icons'

export default function PreviewProductsList({ items, total, subtotal, store }) {
	return (
		<React.Fragment>
			<List
				itemLayout="vertical"
				size="large"
				dataSource={items}
				className="bg-light items-list"
				renderItem={ (item, itemIndex) => (
					<List.Item
						key={itemIndex}
						extra={
							<React.Fragment>
								<div className="float-right pt-3">
									<input 
										value={item.quantity} 
										style={{ width: '60px' }}
										disabled
									/>
								</div>
								<div className="float-left mr-3">								
									<h6 className="mb-0 text-right bold">
										{`$ ${currency((item.net_price * item.quantity))}`}
									</h6>
									<p className="mb-0 text-right">
										<span className="line-through">{`$ ${currency((item.gross_price * item.quantity))}`}</span>
										<br />
										<small>Descuento:</small>{" "}
										<span className="bold">${currency( ((item.gross_price - item.net_price) * item.quantity) )} ({item.discount}%)</span>
									</p>
								</div>
							</React.Fragment>
						}
					>
						<List.Item.Meta
							title={(
								<React.Fragment>
									<p className="mb-0">
										{ item.custom_names[ store.brand ] ? item.custom_names[ store.brand ] : item.description }
										{ item.product_video && (
											<span className="text-link ml-2 no-print" onClick={() => window.open(item.product_video.link)}>
												<FontAwesomeIcon icon={faVideo} className="mr-1" />	
												Ver Video de Producto
											</span>
										)}
									</p>
								</React.Fragment>
							)}		
							className="mb-0"							
							description={(
								<React.Fragment>
									<small>
										Referencia: {item.reference} |{" "}
										Talla: {item.size} |{" "}
										Color: {item.color}
									</small><br />
									<small>
										Tarifa: {item.rate_desc}
									</small>
								</React.Fragment>
							)}
						/>
					</List.Item>
				)}
			/>
			<div className="text-right mt-4">
				<div className="bg-light inline-block mr-2 p-2 mb-2 fs-18">
					Subtotal:
					<span className="bold">$ {currency(subtotal)} COP</span>
				</div>
				<div className="bg-light inline-block mr-2 p-2 mb-2 fs-18">
					Descuento:
					<span className="bold">$ {currency( (subtotal-total) )} COP ({ round( (subtotal-total)/subtotal*100 ) }%)</span>
				</div>
				<div className="inline-block p-2 fs-18" style={{ backgroundColor: '#80e096' }}>
					Total:
					<span className="bold">$ {currency(total)} COP</span>
				</div>
			</div>
		</React.Fragment>
	)
}

const round = value => Math.round((value + Number.EPSILON) * 1) / 1

const currency = value => {
	let rounded = round(parseInt(value))

	return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}