import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInbox } from '@fortawesome/free-solid-svg-icons'

import RecentOpensDrawer from '../../containers/QuoteOpens/RecentOpensDrawer'

export default function QuoteOpensWidget() {
	const [showRecentOpensModal, setShowRecentOpensModal] = useState(false)
	
	return (
		<React.Fragment>
			<div 
				className='inline-block' 
				style={{ cursor:'pointer' }} 
				onClick={() => setShowRecentOpensModal(true)}
			>
				<FontAwesomeIcon icon={faInbox} color="white" size='1x' />
				{" "}Aperturas
			</div>

			{ showRecentOpensModal && (
				<RecentOpensDrawer 
					visible
					onClose={() => setShowRecentOpensModal(false)}
				/>
			)}
		</React.Fragment>
	)
}