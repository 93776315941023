import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form, InputGroup } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message, Switch } from 'antd'

import { storeNote } from '../services'
import { handleError } from '../../../helpers'

export default function NewNoteForm({ quoteId, reload }) {
	const { details: user } = useSelector(state => state.auth)
	const { handleSubmit, register, errors, reset } = useForm()
	const [isPublic, setIsPublic] = useState(false)

	const onSubmit = values => {
		values.user_id = user.id
		values.quote_id = quoteId
		values.is_public = isPublic ? 1 : 0
		storeNote(values)	
			.then(res => {
				message.success(res.data.message)
				reset()
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<InputGroup>
						<Form.Control 
							name="note"
							as="textarea"
							ref={register({ required:true })}
							placeholder="Escriba aquí..."
						/>
						<Button color="primary" type="submit" outline>
							Agregar Nota
						</Button>
					</InputGroup>
					{ errors.note && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Switch checked={isPublic} onChange={() => setIsPublic(!isPublic)} />
					{" "} Nota visible para el cliente
				</Form.Group>
			</Form>
		</React.Fragment>
	)
}