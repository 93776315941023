import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { Spinner } from 'reactstrap'
import { Drawer, Empty } from 'antd'

import { productsBestSellers } from '../services'
import { handleError } from '../../../helpers'
import { Card, CardBody } from 'reactstrap'

export default function BestSellersDrawer({ visible, onClose }) {
	const user = useSelector(state => state.auth.details)
	const [products, setProducts] = useState(null)

	useEffect(() => {
		!products && visible && productsBestSellers({
			store_code: user.active_store.code,
		})
			.then(res => setProducts(res.data.data))
			.catch(error => handleError(error))
	}, [ products, visible, user ])

	return (
		<React.Fragment>
			<Drawer
				visible={visible}
				onClose={() => {
					onClose()
					setProducts(null)
				}}
				title="Productos más vendidos"
				width={450}
			>
				<Form.Group className="mb-1">
					<Form.Control 
						value={`${user.active_store.code} - ${user.active_store.name}`}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Form.Control value="Según los últimos 180 días" disabled />
				</Form.Group>
				{ products ? (
					<div className="bg-light p-2">
						{ products.length > 0 ? (
							<React.Fragment>
								{ products.map( (product, i) => (
									<Card key={product.id} className="mb-2">
										<CardBody className="py-2">
											<p className="bold mb-0">#{i+1} - {product.description}</p>
											<p className="hint-text mb-0">
												{product.color} - {product.size}
											</p>
											<p className="text-primary mb-0">
												{product.quantity} unidades vendidas
											</p>
										</CardBody>
									</Card>
								)) }
							</React.Fragment>
						) : <Empty description="No se encontraron productos" />}
					</div>
				) : (
					<Spinner className="mt-2" />
				)}
			</Drawer>
		</React.Fragment>
	)
}