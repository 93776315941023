import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap'
import { InputGroup, Form } from 'react-bootstrap'
import moment from 'moment'
import { message } from 'antd'

import QuotesTable from '../../Quotes/partials/QuotesTable'

import { getQuotes } from '../../Quotes/services'
import { storeDownloadToken } from '../../DownloadTokens/services'
import { getUri } from '../../../services/springApi'
import { handleError, parsePagination } from '../../../helpers'

export default function QuotesListWidget({ quotes, setQuotes, users, stores, startDate, endDate }) {
	const user = useSelector(state => state.auth.details)
	const [filterType, setFilterType] = useState('number')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(()=>{
		!quotes && users && stores && getQuotes({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'lead,user,store,opensCount,profile',
			sort: '-created_at',
			"filter[user_id]": users.join(','),
			"filter[store_id]": stores.join(','),
			"filter[created_between]": `${moment(startDate).format('YYYY-MM-DD')},${moment(endDate).format('YYYY-MM-DD')}`
		})
			.then(res => {
				setQuotes(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [quotes, filterType, filterValue, users, stores, startDate, endDate, setQuotes, pagination])

	const handleExport = () => {
		const token = Date.now() + Math.floor(1000 + Math.random() * 9000)

		const exportLink = users && stores && `${getUri()}/exports/quotes/${token}?include=lead,user,store&sort=-created_at&filter[active]=1&filter[user_id]=${users.join(',')}&filter[store_id]=${stores.join(',')}&filter[created_between]=${moment(startDate).format('YYYY-MM-DD')},${moment(endDate).format('YYYY-MM-DD')}&ia=${user.role === 'admin' ? '1':'0'}`

		storeDownloadToken({ user_id: user.id, type: 'Exportar Cotizaciones', token })
			.then(() => {
				window.open(exportLink)
				message.success('Cotizaciones exportadas', 10)
			})
			.catch(handleError)
	}


	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className="float-right mt-2">
						<InputGroup>
							<InputGroup.Prepend>
								<Form.Control 
									as="select" 
									className="bg-light"
									value={filterType}
									onChange={e => setFilterType(e.target.value)}
								>
									<option value="number">Buscar por número</option>
									<option value="lead.document">Buscar por cédula</option>
								</Form.Control>
							</InputGroup.Prepend>
							<Form.Control 
								placeholder="Escriba aquí" 
								value={filterValue}
								onChange={e => setFilterValue(e.target.value)}
							/>
							<InputGroup.Append>
								<Button color="primary" onClick={()=>setQuotes(null)}>Buscar</Button>
							</InputGroup.Append>
							<InputGroup.Append>
								<Button color="primary" outline onClick={handleExport}>Exportar</Button>
							</InputGroup.Append>
						</InputGroup>						
					</div>
					<CardTitle>Listado de Cotizaciones</CardTitle>
					<CardSubtitle className="mb-3">Consulte las cotizaciones asociadas a su usuario</CardSubtitle>
					<div className="table-responsive">
						<QuotesTable 
							quotes={quotes} 
							reload={()=>setQuotes(null)} 
							pagination={pagination}
							setPagination={setPagination}
						/>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}