import React from 'react'
import { Card, CardBody, CardTitle, CardSubtitle, Spinner, Row, Col } from 'reactstrap'
import { Divider } from 'antd'
import { Doughnut } from 'react-chartjs-2'

export default function ProfilesGroupsWidget({ report }) {
	const femaleTotal =  report.visits_by_gender.length > 0 ? report.visits_by_gender.filter(r => r.label === 'female')[0].count : 0
	const maleTotal = report.visits_by_gender.length > 0 ? report.visits_by_gender.filter(r => r.label === 'male')[0].count : 0
	const total = report.total_visits
	
	const genderData = {
		labels: [
			`Mujer ( ${round(femaleTotal/total * 100)}% )`,
			`Hombre ( ${round(maleTotal/total * 100)}% )`,
		],
		datasets: [
		  	{
			 	label: '# visitas',
			 	data: [ femaleTotal, maleTotal ],
				backgroundColor: [
					'rgba(255, 10, 10, 0.4)',
					'rgba(10, 10, 255, 0.4)',
				]
		  	}
		],
	}

	const ageData = {
		labels: report.visits_by_age_range.map(record => `${record.label} (${round(record.count / total * 100)}%)`),
		datasets: [
		  	{
			 	label: '# visitas',
				data: report.visits_by_age_range.length > 0 ? report.visits_by_age_range.map(record => record.count) : [],
				backgroundColor: [
					'rgba(185, 185, 185, 0.6)',
					'rgba(252, 186, 3, 0.6)',
					'rgba(224, 136, 20, 0.6)',
					'rgba(153, 224, 20, 0.6)',
					'rgba(20, 166, 224, 0.6)',
					'rgba(139, 20, 224, 0.6)',
					'rgba(20, 207, 224, 0.6)',
			  ],
		  	}
		],
	}

	const groupCountData = {
		labels: report.visits_by_group_count.map(record => `${record.label} acompañantes (${round(record.count / total * 100)}%)`),
		datasets: [
		  	{
			 	label: '# visitas',
			 	data: report.visits_by_group_count.length > 0 ? report.visits_by_group_count.map(record => record.count) : [],
				 backgroundColor: [
					'rgba(185, 185, 185, 0.6)',
					'rgba(252, 186, 3, 0.6)',
					'rgba(224, 136, 20, 0.6)',
					'rgba(153, 224, 20, 0.6)',
					'rgba(20, 166, 224, 0.6)',
					'rgba(139, 20, 224, 0.6)',
					'rgba(20, 207, 224, 0.6)',
			  ],
		  	}
		],
	}

	return (
		<React.Fragment>
			<Row>
				<Col sm="12" md="4">
					<Card>
						<CardBody>
							<CardTitle>Visitas por Genero</CardTitle>
							<CardSubtitle>Total de Visitas Analizadas {total}</CardSubtitle>
							<Divider className="mt-2 mb-3" />
							{ report ? (
								<React.Fragment>
									<Doughnut data={genderData} /> 
								</React.Fragment>
							) : <Spinner size="sm" /> }
						</CardBody>
					</Card>
				</Col>
				<Col sm="12" md="4">
					<Card>
						<CardBody>
							<CardTitle>Visitas por Edad</CardTitle>
							<CardSubtitle>Total de Visitas Analizadas {total}</CardSubtitle>
							<Divider className="mt-2 mb-3" />
							{ report ? (
								<React.Fragment>
									<Doughnut data={ageData} /> 
								</React.Fragment>
							) : <Spinner size="sm" /> }
						</CardBody>
					</Card>
				</Col>
				<Col sm="12" md="4">
					<Card>
						<CardBody>
							<CardTitle>Visitas por Acompañantes</CardTitle>
							<CardSubtitle>Total de Visitas Analizadas {total}</CardSubtitle>
							<Divider className="mt-2 mb-3" />
							{ report ? (
								<React.Fragment>
									<Doughnut data={groupCountData} /> 
								</React.Fragment>
							) : <Spinner size="sm" /> }
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}

const round = value => Math.round((value + Number.EPSILON) * 1) / 1