import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import NewQuoteScreen from './NewQuoteScreen'

export default function Quoting() {
	return (
		<Switch>
			<Route exact path="/cotizador" component={NewQuoteScreen} />

			<Redirect to="/404" />
		</Switch>
	)
}