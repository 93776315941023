import React from 'react'
import { Card, CardBody, CardTitle, CardSubtitle, Spinner } from 'reactstrap'
import { Table, Divider } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default function QuoteOpensWidget({ report }) {
	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Aperturas Recientes</CardTitle>
					<CardSubtitle>Registro de aperturas recientes</CardSubtitle>
					<Divider className="mt-2 mb-3" />
					{ report ? (
						<Table
							dataSource={report.recent_opens}
							rowKey={record => record.id}
							columns={[
								{
									title: 'Contacto',
									dataIndex: 'lead_name',
									render: (text, record) => <Link to={`/cotizaciones/${record.quote_id}`}>{text}</Link>
								},
								{
									title: 'Apertura',
									dataIndex: 'created_at',
									render: text => moment(text).format('D/M/Y h:mm a')
								}
							]}
							size="small"
						/>
					) : <Spinner size="sm" />}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}