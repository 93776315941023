import React from 'react'
import { Descriptions } from 'antd'
import moment from 'moment'
import 'moment/locale/es';

const { Item } = Descriptions

export default function QuoteExpandedRow({ quote }) {
	return (
		<React.Fragment>
			<p className="bold">Detalles del Prospecto</p>
			<Descriptions bordered className="bg-white" size="small">
				<Item label="Nombre">{quote.lead.name}</Item>
				<Item label="Celular">{quote.lead.mobile}</Item>
				<Item label="Correo">{quote.lead.email}</Item>
				<Item label="Genero">{quote.profile ? quote.profile.gender === 'male' ? 'Hombre' : 'Mujer' : 'Sin registro'}</Item>
				<Item label="Rango de Edad">{quote.profile ? quote.profile.age_range : 'Sin registro'}</Item>
			</Descriptions>
			<p className="bold mt-3">Detalles de Cotización</p>
			{ quote.product_groups.map( (group,i) => (
				<div key={i}>
					<p className="mt-2 mb-1">Opción #{i+1}</p>
					<Descriptions bordered className="bg-white" size="small">
						<Item label="Productos">
							{group.products.map((product, i2) => (
								<p className="mb-0" key={`p${i2}`}>
									{ product.description } - Talla: {product.size} - Color: {product.color} - Ctd: {product.quantity}
								</p>
							))}
						</Item>
						<Item label="Total">${currency(group.total)} COP</Item>
					</Descriptions>
				</div>
			)) }
			<p className="mt-3 mb-2">
				<strong>Total de aperturas:</strong> { quote.opens_count } aperturas.{" "}
				{ quote.last_open_at && `Última apertura: ${moment(quote.last_open_at).format('DD/MMM/YYYY h:mm A')}` }
			</p>
			<p className="mb-2">
				<strong>Comentario en Cotización:</strong> { quote.comment ? quote.comment : 'Sin comentarios' }
			</p>
			<p className="mb-0">
				<strong>Comentario en estado actual:</strong> { quote.current_state ? quote.current_state.comment : 'Sin comentario' }
			</p>
		</React.Fragment>
	)
}

const currency = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")