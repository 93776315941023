import React from 'react'
import { Row, Col } from 'reactstrap'
import { Alert, Divider, Popconfirm } from 'antd'
import styled from 'styled-components'
import moment from 'moment'

import LogoSpring from '../../../assets/images/logo_spring.png'
import LogoTempur from '../../../assets/images/logo_tempur.png'
import LogoRosen from '../../../assets/images/logo_rosen.jpg'
import LogoWhatsapp from '../../../assets/images/logo_whatsapp2.png'
import AvalesSpring from '../../../assets/images/avales4.png'
import AvalesRosen from '../../../assets/images/avales_rosen.jpg'

import PreviewProductsList from './PreviewProductsList'
import PublicNotesList from '../../Notes/partials/PublicNotesList'
import PreviewProductsHtmls from './PreviewProductsHtmls'

export default function QuoteDetails({ quote, handleEdit, handleDeleteOption }) {
	const brand = window.location.host.split('.')[1]

	return (
		<React.Fragment>

			{ moment().diff(moment(quote.expire_at), 'days') > 0 && (
				<Alert message="Esta cotización se encuentra vencida. Puede ponerse en contacto con nostros para cotizar de nuevo." type="warning" />
			)}

			<Row className="p-4" noGutters={false}>
				<Col className="mb-2" sm="4">
					{ (quote.store.logos.includes('spring') || !quote.store.logos) && (
						<img src={LogoSpring} width="115px" alt="Logo Spring" className="mb-3 mr-2" />
					)}
					{ quote.store.logos && quote.store.logos.includes('tempur') && (
						<img src={LogoTempur} width="115px" alt="Logo Tempur" className="mr-3 mb-2" />
					)}
					{ quote.store.logos && quote.store.logos.includes('rosen') && (
						<img src={LogoRosen} width="115px" alt="Logo Rosen" className="mb-3 mr-2" />
					)}
					<Text><strong>COTIZACIÓN</strong> {quote.store.code}_{quote.number}</Text>
					<Text><strong>CREADA EL:</strong> {moment(quote.created_at).format('D/M/YYYY')}</Text>
					<Text><strong>VENCE EL:</strong> {moment(quote.expire_at).format('D/M/YYYY')}</Text>
				</Col>
				<Col className="mb-2">
					<Text><strong>CONTACTO PRINCIPAL:</strong></Text>
					<Text>{quote.lead.name}</Text>
					<Text>CC {quote.lead.document}</Text>
					<Text>{quote.lead.email}</Text>
					<Text>{quote.lead.mobile}</Text>
					{handleEdit && (
						<p className="text-link no-print mt-2" onClick={handleEdit}>
							Modificar
						</p>
					)}
				</Col>
				<Col className="mb-2">
					<Text><strong>REALIZADA POR:</strong></Text>
					<Text>{quote.user.name}</Text>
					<Text>{quote.store.name}</Text>
					<Text>{quote.store.address}</Text>
					<Text>Tel: {quote.user.mobile ? quote.user.mobile : quote.store.mobile ? `${quote.store.mobile} | ${quote.store.phone ? quote.store.phone : ''}` : ''}</Text>
				</Col>
				<Col className="text-center mb-2" sm="2">
					{ quote.store.mobile && (
						<a href={`https://api.whatsapp.com/send?phone=57${quote.store.mobile}&text=Hola,%20deseo%20mas%20informaci%C3%B3n%20de%20mi%20cotizaci%C3%B3n%20%23${quote.number}`} target="_blank" rel="noopenner noreferrer">
							<img src={LogoWhatsapp} alt="Logo Whatsapp" width="75px" />
						</a>
					)}
				</Col>
			</Row>

			<Divider className="mb-3 mt-0" />

			{ quote.product_groups.map( (group,i) => (
				<div key={i} className="mb-3">
					<div>
						<span className="bold mb-2">Opción #0{i+1}</span>
						<Divider type="vertical" />
						{ quote.product_groups.length > 1 && handleDeleteOption && !quote.published_at && (
							<Popconfirm
								title="Esta acción no se puede revertir"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger: true }}
								onConfirm={() => handleDeleteOption(group.products)}
							>
								<span className='text-link'>Eliminar Opción</span>
							</Popconfirm>
						)}
					</div>
					<PreviewProductsList 
						items={group.products} 
						total={parseInt(group.total)}
						subtotal={parseInt(group.subtotal)}
						store={quote.store}
					/>
				</div>
			)) }

			<PublicNotesList notes={quote.public_notes} />

			<Divider className="my-3" />

			{ quote.product_groups.map( (group,i) => (
				<div key={i} className="mb-3">
					<PreviewProductsHtmls items={group.products} />
				</div>
			)) }

			<div className="text-center">
				{ brand === 'rosen' ? (
					<img src={AvalesRosen} alt="Avales Rosen" className="mb-3 avales-img" />
				) : (
					<React.Fragment>
						<p className="bold mb-3">AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES INDUSTRIAS SPRING S.A.S.</p>
						<p className="small text-muted mb-4 px-5">Manifiesto que he sido informado por INDUSTRIAS SPRING S.A.S. actuará como responsable del tratamiento de datos personales de los cuales soy titular y que podrá recolectar, usar y tratar mis datos conforme la Política de Tratamiento de Datos personales de la compañía, disponible: http://www.colchonesspring.com.co/ COTIZACIÓN NO VALIDA COMO FACTURA DE VENTA y presente su factura para eventuales reclamaciones. LOS PRODUCTOS EN PROMOCIÓN NO TIENEN CAMBIO.</p>
						<img src={AvalesSpring} alt="Avales Spring" className="mb-3 avales-img" />
					</React.Fragment>
				)}
			</div>
		</React.Fragment>
	)
}

const Text = styled.p`
	font-size: 16px;
	margin-bottom: 0px;
`