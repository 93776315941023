import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import GenerationInsights from './GenerationInsights'
import ProfileInsights from './ProfileInsights'
import GenderInsights from './GenderInsights'

export default function OptionPicker({ type, options }) {
	const [optionPicked, setOptionPicked] = useState(null)

	const drawerProps = {
		visible: optionPicked ? true : false,
		onClose: ()=>setOptionPicked(null),
		slug: optionPicked
	}

	return (
		<React.Fragment>
			<Row noGutters>
				{ options.map( (opt, i) => (
					<Col key={i}>
						<OptionCard onClick={() => opt.handleClick ? opt.handleClick() : setOptionPicked(opt.slug)}>
							<FontAwesomeIcon icon={opt.icon} className="icon" />
							<h6>{opt.title}</h6>
						</OptionCard>
					</Col>
				)) }
			</Row>
			{ type === 'generation' && <GenerationInsights {...drawerProps} />}
			{ type === 'profile' && <ProfileInsights {...drawerProps} />}
			{ type === 'gender' && <GenderInsights {...drawerProps} />}
		</React.Fragment>
	)
}

const OptionCard = styled.div`	
	padding: 20px;
	text-align: center;
	background: #fff;
	color: #e42537;
	border: 1px solid #e42537;
	margin: 5px;
	& .icon {
		font-size: 21px;
	}
	& h6 {
		margin-bottom: 0px;
	}
	&:hover {
		cursor: pointer;
		background: #e42537;
		color: #fff;
	}
	&:hover h6 {
		color: #fff;
	}
`