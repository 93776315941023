import React from 'react'
import styled from 'styled-components'

import LogoSpring from '../assets/images/logo_spring.png'
import LogoRosen from '../assets/images/logo_rosen.jpg'

export default function AuthLayout({ children }) {
	const brand = window.location.host.split('.')[1]

	return (
		<Container theme={brand === 'rosen' ? 'dark' : 'light'}>
			{ brand === 'rosen' ? (
				<img src={LogoRosen} alt="Logo Rosen" width="170px" />
			) : (
				<img src={LogoSpring} alt="Logo Colchones Spring" width="150px" />
			)}
			<FormContainer>
				{children}
			</FormContainer>
			<p>Cotizador desarrollado por{" "}
				<a href="//smart4.com.co" target="_blank" rel="noreferrer">
					Smart4 Solutions
				</a>
				{" | "}Versión {process.env.REACT_APP_VERSION}
			</p>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: ${props => props.theme === 'dark' ? '#38484E' : '#f7f8f9'};
	color: ${props => props.theme === 'dark' ? '#ffffff' : '#333333'};
	display: flex;
  	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const FormContainer = styled.div`
	background-color: #fff;
	padding: 35px;	
	max-width: 450px;
	width: 100%;
	text-align: center;
	margin: 20px auto;
	color: #333;
`