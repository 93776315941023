import React from 'react'

export default function PreviewProductsHtmls({ items }) {

	if(!items || !Array.isArray(items) || items.length === 0)
		return null;

	return (
		<React.Fragment>
			{ items.map(item => (
				<div key={`html_${item.pivot_id}`}>
					{ item.product_html && (
						<div dangerouslySetInnerHTML={{ __html: item.product_html.html }} /> 
					)}
				</div>
			)) }
		</React.Fragment>
	)
}