import React from 'react'
import { useForm } from 'react-hook-form'
import { Divider, Drawer, message } from 'antd'
import { Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

import LeadProfileForm from '../Profiles/partials/LeadProfileForm'
import WinProbability from './partials/WinProbability'
import OptionPicker from './partials/OptionPicker'
import { faMale, faFemale } from '@fortawesome/free-solid-svg-icons'

import { storeProfile } from '../Profiles/services'
import { updateQuote } from '../Quotes/services'
import { handleError } from '../../helpers'

export default function QuoteAiDrawer({ visible, onClose, quote }) {
	const { handleSubmit, register, errors } = useForm()

	const onSubmit = values => {
		storeProfile(values)
			.then(res => updateQuote(quote.id, { profile_id: res.data.data.id }))
			.then(() => {
				message.success('Perfil actualizado exitosamente')
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Drawer
				visible={visible}
				onClose={onClose}
				width={350}
				title="Spring AI - Análisis de Cotización"
				bodyStyle={{ backgroundColor: '#f8f8f8', padding: '15px' }}
			>

				<CardTitle>Perfil del Comprador</CardTitle>
				<Divider className="mt-0 mb-2" />
				<CardBody>
					{ visible && (
						<Form onSubmit={handleSubmit(onSubmit)}>
							<LeadProfileForm 
								profile={quote && quote.profile} 
								register={register} 
								errors={errors} 
							/>
							<Button type="submit" color="primary" outline className="mr-2">
								Actualizar Perfil
							</Button>
						</Form>
					)}
				</CardBody>

				<CardTitle>Probabilidad de Compra</CardTitle>
				<Divider className="mt-0 mb-2" />
				<CardBody>
					<WinProbability quote={quote} />
				</CardBody>

				<CardTitle>Análisis por Genero</CardTitle>
				<Divider className="mt-0 mb-2" />
				<CardBody>
					<OptionPicker 
						type="gender"
						options={[
							{ title: 'Hombre', slug: 'male', icon: faMale },
							{ title: 'Mujer', slug: 'female', icon: faFemale },
						]}
					/>
				</CardBody>
			</Drawer>
		</React.Fragment>
	)
}

const CardTitle = styled.p`
	margin-bottom: 0px;
	text-align: center;
	font-weight: bold;
	color: #666;
`

const CardBody = styled.div`
	border: 3px solid #f8f8f8;
	padding: 10px;
	margin-bottom: 15px;
	background-color: #fff;
`