export const USER_LOGIN = "auth/USER_LOGIN"
export const USER_LOGOUT = "auth/USER_LOGOUT"
export const USER_NEW_STORE = "auth/USER_NEW_STORE"

export const userLogin = data => ({
   type: USER_LOGIN,
   payload: data
})

export const userLogout = () => ({
   type: USER_LOGOUT,
   payload: null
})

export const userNewStore = details => ({
   type: USER_NEW_STORE,
   payload: details
})