import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import SearchResults from './SearchResults'

export default function Search() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/buscar" component={SearchResults} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}