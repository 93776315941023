import springApi from '../../services/springApi'

export const getLeads = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/leads', {params})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeLead = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/leads', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateLead = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/leads/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const validateEmail = email => (
	new Promise((resolve, reject) => { 
		springApi()
			.post('email_validation', { email })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)