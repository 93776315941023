import React from 'react'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCouch, faCloud, faPlusCircle, faBed } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

export default function SuggestsCard({ handleClick }) {
	return (
		<React.Fragment>
			<Row noGutters={true} className="mt-1">
				<Col className="mb-1">
					<CategoryCard onClick={()=>handleClick('COLCHONES')}>
						<h6><FontAwesomeIcon icon={faBed} /> Colchones</h6>
					</CategoryCard>
				</Col>
				<Col className="mb-1">
					<CategoryCard onClick={()=>handleClick('ALMOHADAS')}>								
					<h6><FontAwesomeIcon icon={faCloud} /> Almohadas</h6>
					</CategoryCard>
				</Col>
				<Col className="mb-1">
					<CategoryCard onClick={()=>handleClick('BASES Y PATAS')}>								
						<h6><FontAwesomeIcon icon={faCouch} />Bases y Patas</h6>
					</CategoryCard>
				</Col>
				<Col className="mb-1">
					<CategoryCard onClick={()=>handleClick('LENCERIA')}>								
						<h6><FontAwesomeIcon icon={faPlusCircle} /> Lencería</h6>
					</CategoryCard>
				</Col>
			</Row>
		</React.Fragment>
	)
}

const CategoryCard = styled.div`
	text-align: center;
	background-color: #f8f9fa;
	cursor: pointer;
	padding: 10px 10px;
	margin: 0px 5px;
	&:hover {
		background-color: #3b4351;
		color: #fff;
	}
	&:hover h6 {
		color: #fff;		
	}
	& h6 {
		margin-bottom: 0px;
		font-size: 16px;
	}
	& svg {
		margin-right: 5px;
	}
`