import React, { useState } from 'react'
import { CardTitle, CardSubtitle, Button, CardHeader, Row, Col, Spinner, Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxes, faLightbulb, faComments, faPrint, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { Divider, message, Popconfirm, Tooltip } from 'antd'
import moment from 'moment'

import { notifyQuote, updateQuote } from '../services'
import { handleError } from '../../../helpers'

export default function ProfileHeader({ quote, openAiDrawer, openAddOption, reload }) {
	const [tooltipLabel, setTooltipLabel] = useState('Copiar')
	const [loadingSendEmail, setLoadingSendEmail] = useState(false)

	const copyLinkToClipboard = () => {
		navigator.clipboard.writeText(`https://${window.location.host}/v/${quote.id}`)

		setTooltipLabel('¡Copiado!')

		setTimeout(() => {
			setTooltipLabel('Copiar')
		}, 1000);
	}

	const handleSendEmail = () => {
		setLoadingSendEmail(true)
		notifyQuote(quote.id)
			.then(res => message.info(res.data.message))
			.catch(handleError)
			.finally(() => setLoadingSendEmail(false))
	}

	const handlePublishQuote = () => {
		updateQuote(quote.id, { published_at: moment().format('YYYY-MM-DD HH:mm:ss') })
			.then(() => {
				message.success('Cotización publicada exitosamente')
				reload()
			})
			.catch(handleError)
	}

	return (
		<CardHeader className="no-print bg-white">
			<Row>
				<Col md="4">
					<CardTitle>Cotización Número #{quote.number}</CardTitle>
					<CardSubtitle>
						{ !quote.voided_at ? (
							<React.Fragment>
								{ quote.published_at ? (
									<React.Fragment>
										<Badge color='success'>Publicada</Badge>
										<Divider type="vertical" />
										Link:{" "}
										<Tooltip 
											title={tooltipLabel} 
											placement="right" 
											onClick={()=>copyLinkToClipboard()}
										>
											<span className="text-link small" id="linkToCopy">
												https://{window.location.host}/v/{quote.id}
											</span>
										</Tooltip>
									</React.Fragment>
								) : (
									<Badge color='warning'>Cotización en borrador</Badge>
								)}
							</React.Fragment>
						) : (
							<Badge color='danger'>Cotización Anulada</Badge>
						)}
					</CardSubtitle>
				</Col>
				<Col className="text-right pt-2">
					{ quote.published_at ? (
						<React.Fragment>
							<Button 
								color="primary" 
								onClick={()=>window.open(`https://api.whatsapp.com/send?phone=57${quote.lead.mobile}&text=Puede%20encontrar%20su%20cotizaci%C3%B3n%20en%20el%20siguiente%20link%20https%3A%2F%2Fcotizador.spring.com.co%2Fv%2F${quote.id}`)}
								outline
							>
								<FontAwesomeIcon icon={faComments} /> Whatsapp
							</Button>
							{ quote.lead.email && ( 
								<React.Fragment>
									<Divider type="vertical" />
									<Popconfirm
										title="¿Desea enviar cotización al correo del prospecto?"
										okText="Enviar Correo"
										cancelText="Cancelar"
										onConfirm={handleSendEmail}
										okButtonProps={{ danger:true }}
									>
										<Button outline color="primary" disabled={loadingSendEmail}>
											<FontAwesomeIcon icon={faPaperPlane} /> Enviar Correo{" "}
											{loadingSendEmail && <Spinner size="sm" />}
										</Button>
									</Popconfirm>
								</React.Fragment>
							)}
							<Divider type="vertical" />
							<Button outline color="primary" onClick={openAddOption}>
								<FontAwesomeIcon icon={faBoxes} /> Nueva Opción
							</Button>
							<Divider type="vertical" />
							<Tooltip title="Imprimir">
								<Button outline color="primary" onClick={()=>window.print()}>
									<FontAwesomeIcon icon={faPrint} />
								</Button>
							</Tooltip>
							<Divider type="vertical" />
							<Button color="primary" onClick={openAiDrawer}>
								<FontAwesomeIcon icon={faLightbulb} /> Análisis IA
							</Button>
						</React.Fragment>
					) : (
						<React.Fragment>
							<Button outline color="primary" onClick={openAddOption}>
								<FontAwesomeIcon icon={faBoxes} /> Nueva Opción
							</Button>
							<Divider type="vertical" />
							<Popconfirm
								title="Una vez publicada será visible para el cliente y no podrá eliminar opciones"
								okText="Publicar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={handlePublishQuote}
							>
								<Button color="primary">
									Publicar Cotización
								</Button>
							</Popconfirm>
						</React.Fragment>
					)}
				</Col>
			</Row>
		</CardHeader>
	)
}