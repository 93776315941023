import React from 'react'
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
import { Divider, Timeline, Empty } from 'antd'
import moment from 'moment'

export default function QuoteOpensWidget({ quote }) {
	return (
		<Card>
			<CardBody>
				<CardTitle>Historíal de Aperturas</CardTitle>
				<CardSubtitle>Registro de aperturas del link a clientes</CardSubtitle>
				<Divider className="mt-2 mb-3" />
				{ quote.opens.length > 0 ? (
					<Timeline>
						<Timeline.Item color="red">
							Cotización creada: {moment(quote.created_at).format('D/MMM/Y h:mm a')}
						</Timeline.Item>
						{ quote.opens.map(open => 
							<Timeline.Item key={open.id} color="red">
								{moment(open.created_at).format('D/M/Y h:mm a')}
							</Timeline.Item>
						)}
					</Timeline>
				) : <Empty description="No tiene aperturas registradas" />}
			</CardBody>
		</Card>
	)
}