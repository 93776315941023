import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardTitle, CardSubtitle, Alert } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import NewNoteForm from '../../../Notes/partials/NewNoteForm'
import NoteCard from '../../../Notes/partials/NoteCard'

import { getNotes } from '../../../Notes/services'
import { handleError } from '../../../../helpers'

export default function QuoteNotesWidget({ quote }) {
	const [notes, setNotes] = useState(null)

	useEffect(() => {
		!notes && getNotes({ 'filter[quote_id]': quote.id })
			.then(res => setNotes(res.data.data))
			.catch(handleError)
	}, [ notes, quote ])

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Comentarios Adicionales</CardTitle>
					<CardSubtitle>Notas asociadas a la cotización</CardSubtitle>
					<Divider className="mt-2 mb-3" />
					<Alert color='info' className='p-1'>
						<p className='mb-0'>
							<b>Nota inicial:</b> {quote.comment ? quote.comment : <em>Sin comentarios iniciales</em>}
						</p>
						<p className='small mb-0'><em>Nota visible solo para asesores</em></p>
					</Alert>
					{ notes ? (
						<React.Fragment>
							{ notes.length > 0 ? (
								<React.Fragment>
									{ notes.map(note => (
										<NoteCard 
											key={note.id} 
											note={note} 
											reload={() => setNotes(null)}
										/>
									)) }
								</React.Fragment>
							) : (
								<Empty description="No tiene notas creadas" imageStyle={{ height:'60px' }} />
							)}
						</React.Fragment>
					) : (
						<Spinner animation='border' size='sm' />
					)}
					<Divider className='mb-3' />
					<NewNoteForm quoteId={quote.id} reload={() => setNotes(null)} />
				</CardBody>
			</Card>
		</React.Fragment>
	)
}