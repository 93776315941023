import { store } from "../store"
import { getDomain } from "../services/springApi"

import Echo from "laravel-echo"
window.Pusher = require('pusher-js')

const state = store.getState()
const authToken = state.auth.token

export const createSocketConnection = () => {
	if (!window.Echo) {
		window.Echo = new Echo({
			broadcaster: 'pusher',
			key: 'ABCDEF1234',
			wsHost: 'apicotizador.spring.com.co',
			wsPort: 6002,
			wssPort: 6002,
			encrypted: true,
			forceTLS: true,
			disableStats: true,
			enabledTransports: ['ws', 'wss'],
			auth: { headers: { Authorization: `Bearer ${authToken}` } },
			authEndpoint: `${getDomain()}/broadcasting/auth`,
			cluster: 'global'
		})
	}
}