import React from 'react'
import { Alert } from 'reactstrap'
import { message, Popconfirm } from 'antd'

import { deleteNote } from '../services'
import { handleError } from '../../../helpers'

export default function NoteCard({ note, reload }) {
	
	const handleDelete = id => {
		deleteNote(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<Alert color={note.is_public ? 'success' : 'warning'} className="p-1 mb-1">
				<p className='mb-0'>
					<b>{note.user_name}: </b>{note.note}
				</p>
				<div className='float-right'>
					<Popconfirm
						title="Esta acción no se puede revertir"
						okText="Eliminar"
						okButtonProps={{ danger:true }}
						cancelText="Cancelar"
						onConfirm={() => handleDelete(note.id)}
					>
						<p className='text-link mb-0'>Eliminar</p>
					</Popconfirm>
				</div>
				<p className='small mb-0'>
					<em>{note.is_public ? 'Nota visible para el cliente' : 'Nota oculta al cliente'}</em>
				</p>
			</Alert>
		</React.Fragment>
	)
}