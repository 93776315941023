import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'

import DeliverySimulator from './DeliverySimulator'

export default function Deliveries() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/entregas" component={DeliverySimulator} />

				<Redirect to="404" />
			</Switch>
		</React.Fragment>
	)
}