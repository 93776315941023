import springApi from '../../services/springApi'

export const login = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/auth/login', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const logout = () => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/auth/logout')
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getUsers = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/users`, {params})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)


export const getUser = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/users/${id}`, {params})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateUser = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/users/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)