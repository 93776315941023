import springApi from '../../services/springApi'

export const getNotes = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/notes', {params})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeNote = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/notes', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateNote = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/notes/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteNote = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/notes/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)