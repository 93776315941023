import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import { Table, Tooltip, Divider, Progress } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment/locale/es'

import Pagination from '../../../components/Pagination'
import QuoteAiDrawer from '../../SpringAI/QuoteAiDrawer'
import QuoteExpandedRow from './QuoteExpandedRow'
import LogStateModal from './LogStateModal'

export default function QuotesTable({ quotes, reload, pagination, setPagination }) {
	const [quoteStateModal, setQuoteStateModal] = useState(null)
	const [quoteAiDrawer, setQuoteAiDrawer] = useState(null)

	const columns = [
		{
			title: 'Número',
			dataIndex: 'number',
			render: (text, record) => <Link to={`/cotizaciones/${record.id}`}>{text}</Link>
		},
		{
			title: 'Publicada',
			dataIndex: 'published_at',
			render: t => t ? <Badge color='success'>Publicada</Badge> : <Badge color='warning'>En Borrador</Badge>
		},
		{
			title: 'Prospecto',
			dataIndex: 'lead',
			render: (text, record) => <Link to={`/cotizaciones/${record.id}`}>{text.name}</Link>
		},
		{
			title: 'Tienda',
			dataIndex: 'store',
			render: text => text.code
		},
		{
			title: 'Asesor',
			dataIndex: 'user',
			render: text => text.name
		},
		{
			title: 'Fecha de Creación',
			dataIndex: 'created_at',
			render: date => moment(date).format('DD/MMM/YYYY h:mm A')
		},
		{
			title: 'Vencimiento',
			dataIndex: 'expire_at',
			render: (date, record) => round(moment.duration(moment(date).diff(moment())).asDays()) > 0 ? (`${moment(date).format('DD/MMM/YYYY')} (${round(moment.duration(moment(date).diff(moment())).asDays())} días)`) : round(moment.duration(moment(date).diff(moment())).asDays()) === 0 ? <Badge color="info">Vence Hoy</Badge> : <Badge color="warning">Cotización Vencida</Badge>
		},
		{
			title: 'Estado',
			dataIndex: 'current_state',
			render: (text, record) => (
				<React.Fragment>
					{ !record.voided_at ? (
						<React.Fragment>
							<Badge 
								color={text ? text.status === 'En toma de decisión' ? 'warning' : 'info' : 'dark'} 
								className="text-link text-white p-1"
								onClick={()=>setQuoteStateModal(record)}
							>
								{text ? text.status : 'Estado Inicial'}
							</Badge>
							{ record.win_probability > 0 && record.win_probability !== 100 && (
								<Progress
									strokeColor="#17a2b8"
									trailColor="#888"
									percent={record.win_probability} 
									size="small" 
									showInfo={false}
								/>
							)}
						</React.Fragment>
					) : (
						<Badge color="danger">Anulada</Badge>
					)}
				</React.Fragment>
			)
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Ver Cotización">
						<Link to={`/cotizaciones/${record.id}`}>
							<FontAwesomeIcon className="text-link" icon={faExternalLinkAlt} />
						</Link>
					</Tooltip>
					{ !record.voided_at && (
						<React.Fragment>
							<Divider type="vertical" />
							<Tooltip title="Spring AI">
								<FontAwesomeIcon 
									className="text-link" 
									icon={faLightbulb} 
									onClick={() => setQuoteAiDrawer(record)}
								/>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table
				dataSource={quotes}
				rowKey={record => record.id}
				columns={columns}
				expandable={{
					expandedRowRender: record => <QuoteExpandedRow quote={record} />,
				}}
				pagination={false}
				loading={!quotes}
				size="small"
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}				
			/>
			<LogStateModal 
				visible={quoteStateModal ? true : false}
				onClose={() => setQuoteStateModal(null)}
				quote={quoteStateModal}
				reload={reload}
			/>
			<QuoteAiDrawer 
				visible={quoteAiDrawer ? true : false}
				onClose={() => setQuoteAiDrawer(null)}
				quote={quoteAiDrawer}
			/>
		</React.Fragment>
	)
}

const round = value => Math.round((value + Number.EPSILON) * 1) / 1