import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import QuotesList from './QuotesList'
import QuoteProfile from './QuoteProfile'

export default function Quotes() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/cotizaciones" component={QuotesList} />
				<Route exact path="/cotizaciones/:id" component={QuoteProfile} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}