import React from 'react'
import { Form } from 'react-bootstrap'

export default function LeadProfileForm({ register, errors, profile }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Genero</Form.Label><br />
				<Form.Check 
					name="gender" 
					value="male" 
					ref={register({required:true})}
					type="radio" 
					inline 
					className="mr-1" 
					defaultChecked={profile && profile.gender === 'male'}
				/>
				<span className="mr-3">Hombre</span>
				<Form.Check 
					name="gender" 
					value="female" 
					ref={register({required:true})} 
					type="radio" 
					inline 
					className="mr-1" 
					defaultChecked={profile && profile.gender === 'female'}
				/>
				<span className="mr-3">Mujer</span>
				{ errors.gender && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Rango de Edad</Form.Label>
				<Form.Control
					as="select"
					name="age_range"
					ref={register({required:true})}  
					defaultValue={profile && profile.age_range}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option>Menor de 20 años</option>
					<option>21 a 30 años</option>
					<option>31 a 40 años</option>
					<option>41 a 60 años</option>
					<option>Mayor de 61 años</option>
				</Form.Control>
				<Form.Text className="text-muted">Estime la edad del tomador de decisión</Form.Text>
				{ errors.age_range && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Total de Acompañantes</Form.Label>
				<Form.Control 
					as="select"
					name="group_count"
					ref={register({required:true})}
					defaultValue={profile && profile.group_count}
				>
					<option value="0">Sin acompañantes</option>
					<option value="1">1 acompañante</option>
					<option value="2">2 acompañantes</option>
					<option value="3">3 acompañantes</option>
					<option value="4">4 acompañantes</option>
					<option value="5">5 acompañantes</option>
					<option value="6">6 acompañantes</option>
				</Form.Control>
				{ errors.group_count && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}