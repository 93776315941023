import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Empty } from 'antd'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchProductsByCode } from '../../Products/services'
import moment from 'moment'

export default function StockAlertForm({ register, errors, selectedProduct, setSelectedProduct, stockAlert }) {
	return (
		<React.Fragment>
			<Row>				
				{ selectedProduct && (
					<React.Fragment>
						<Form.Group as={Col} md="6">
							<Form.Label>Producto <span className='text-danger'>*</span></Form.Label>
							<ServerSideSelect
								value={selectedProduct}
								placeholder="Buscar por código LN"
								fetchOptions={searchProductsByCode}
								onChange={value => setSelectedProduct(value)}
								className="full-width"
								notFoundContent={<Empty description="Sin productos encontrados" imageStyle={{ height: 70 }} />}
							/>
						</Form.Group>
						<Form.Group as={Col} md="2">
							<Form.Label>Fecha (DD-MM-YYYY)</Form.Label>
							<Form.Control disabled value={moment().format('DD-MM-YYYY')} />
						</Form.Group>
					</React.Fragment>
				)}
				<Form.Group as={Col}>
					<Form.Label>Unidades Disponibles <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						name="units"
						ref={register({required:true})}
						type="number"
						placeholder='Indique 0 si no tiene inventario'
						defaultValue={stockAlert && stockAlert.units}
					/>
					{ errors.units && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Observaciones <small>(opcional)</small></Form.Label>
				<Form.Control 
					as="textarea"
					name="observations"
					ref={register({required:false})}
					defaultValue={stockAlert && stockAlert.observations}
					placeholder='Escriba aquí...'
				/>
			</Form.Group>
		</React.Fragment>
	)
}