import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Row, Col, Spinner } from 'reactstrap'
import { Statistic } from 'antd'
import moment from 'moment'

import TopFiltersBar from './partials/TopFiltersBar'
import QuoteStatesWidget from './partials/QuoteStatesWidget'
import QuoteCategoriesWidget from './partials/QuoteCategoriesWidget'
import QuoteOpensWidget from './partials/QuoteOpensWidget'
import QuotesListWidget from './partials/QuotesListWidget'
import { getUser, getUsers } from '../Authentication/services'
import { handleError } from '../../helpers'
import { getGeneralReport } from './services'

export default function GeneralReport() {
	const user = useSelector(state => state.auth.details)
	const [startDate, setStartDate] = useState(moment().startOf('month'))
	const [endDate, setEndDate] = useState(moment().endOf('month'))
	const [storesIds, setStoresIds] = useState(null)
	const [usersIds, setUsersIds] = useState(null)
	const [stores, setStores] = useState(null)
	const [users, setUsers] = useState(null)
	const [report, setReport] = useState(null)
	const [quotes, setQuotes] = useState(null)

	useEffect(()=>{
		!stores && getUser(user.id)
			.then(res => {
				setStores(res.data.data.stores)
				const allStoreIds = res.data.data.stores.map(s => s.id)
				setStoresIds(allStoreIds)
			})
			.catch(error => handleError(error))
	}, [stores, user])

	useEffect(()=>{
		let userStoresIds = stores ? stores.map(s => s.id).join(',') : []		
		!users && stores && getUsers({ "filter[current_store]": userStoresIds })
			.then(res => {
				setUsers(res.data.data)
				const allUsersIds = res.data.data.map(u => u.id)
				user.role === 'agent' ? setUsersIds([user.id]) : setUsersIds(allUsersIds)
			})
			.catch(error => handleError(error))
	}, [users, stores, user])

	useEffect(()=>{
		!report && usersIds && getGeneralReport({
			from: moment(startDate).format('YYYY-MM-DD'),
			to: moment(endDate).format('YYYY-MM-DD'),
			stores_ids: JSON.stringify(storesIds),
			users_ids: JSON.stringify(usersIds),
		})
			.then(res => setReport(res.data))
			.catch(error => handleError(error))
	}, [report, storesIds, usersIds, startDate, endDate])

	const statCards = report ? [
		{
			title: "Cotizaciones Creadas",
			value: report.quotes_count,
			suffix: "nuevas cotizaciones"
		},
		{
			title: "Cotizaciones con Aperturas",
			value: report.opened_quotes_count,
			suffix: report.quotes_count ? `${round(report.opened_quotes_count/report.quotes_count*100)}% de cotizaciones` : 'cotizaciones'
		},
		{
			title: "Cotizaciones sin seguimiento",
			value: report.nostate_quotes_count,
			suffix: report.quotes_count ? `${round(report.nostate_quotes_count/report.quotes_count*100)}% con estado inicial` : 'cotizaciones'
		},
		{
			title: "Fecha de última cotización",
			value: report.last_quote_at ? moment(report.last_quote_at).format('YYYY/MMM/DD') : 'Sin fecha',
			suffix: report.last_quote_at ? moment(report.last_quote_at).format('H:mm a') : '',
		}
	] : []

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<TopFiltersBar
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
						storesIds={storesIds}
						setStoresIds={setStoresIds}
						usersIds={usersIds}
						setUsersIds={setUsersIds}
						user={user}
						users={users}
						stores={stores}
						reload={()=> { setReport(null); setQuotes(null) }}
					/>
				</CardBody>
			</Card>
			<Row>
				{ report ? statCards.map( (stat,i) => (
					<Col xs="6" md="3" key={i}>
						<Card>
							<CardBody>
								<Statistic {...stat} />
							</CardBody>
						</Card>
					</Col>
				)) : (
					<Col className="text-center mt-2 mb-4">
						<Spinner size="sm" />
					</Col>
				)}
			</Row>
			<Row>
				<Col sm="12" md="6" lg="4">					
					<QuoteStatesWidget report={report} />
				</Col>
				<Col sm="12" md="6" lg="4">					
					<QuoteCategoriesWidget report={report} />
				</Col>
				<Col sm="12" md="6" lg="4">
					<QuoteOpensWidget report={report} />
				</Col>
			</Row>
			<QuotesListWidget 
				quotes={quotes}
				setQuotes={setQuotes}
				stores={storesIds}
				users={usersIds}
				startDate={startDate}
				endDate={endDate}
			/>
		</React.Fragment>
	)
}

const round = value => Math.round((value + Number.EPSILON) * 1) / 1