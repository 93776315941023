import React from 'react'
import { Drawer } from 'antd'

export default function ProfileInsights({ visible, onClose, slug }) {

	const profiles = {
		emancipacion: {
			title: 'Emancipación Familiar y Económica',
			mindset: [
				'Salir del nucleo familiar para construir mi vida.',
				'Aprecio por mis propios logros.',
				'Esforzarme para vivir y disfrutar las cosas.'
			],
			necesities: [
				'Quiero referencias y guías simples en la compra del colchón.',
				'El precio es un factor importante de compra.',
				'Encontrar flexibilidad y rapidez en tiempos y procesos de instalación y entrega.',
			],
			situations: [
				'Solucionar el lugar donde dormir.',
				'Cambiar de ciudad.',
				'Irse a vivir solo por primera vez.'
			],
			drivers: [
				'Ajustado a mi presupuesto',
				'Praciticidad y portabilidad',
				'Fácil entrega',
				'Opciones de pago',
				'Recuperación de sueño extrema',
				'Combos con mobiliario'
			],
			emotionals: [
				'Logro de independencia',
				'Adaptación a nuevos entornos',
				'Expandir vida sexual'
			]
		},
		pareja: {
			title: 'Unión de Pareja & Casarse',
			mindset: [
				'Me esfuerzo por lograr estabilidad de pareja',
				'Invierto en el bienestar y calidad de vida de mi familia',
				'Quiero ver el resultado de mis esfuerzos'
			],
			necesities: [
				'Quiero sentir que estoy haciendo una compra inteligente para empezar a construir mi familia',
				'Sentir que existen verdaderas ventajas comparativas y diferenciales reales.',
				'Sentir que encuentro una mejor versión del producto que mis expectativas previas.',
				'Compra 100% segura y durabilidad del producto.',
			],
			situations: [
				'Irse a vivir juntos.',
				'Casarse',
				'Alquiler y compra de vivienda',
				'Tener primer bebé'
			],
			drivers: [
				'Temperatura y confort ideal para los dos.',
				'Grande para que el bebé este seguro.',
				'Vida Util extendida del colchon',
			],
			emotionals: [
				'Negociación y consenso de pareja.',
				'Necesidad compartida.',
				'Hacer una compra inteligente.',
				'Estabilidad de pareja.',
				'Espacio para estar con mi bebé con amplitud.',
			]
		},
		crecimiento: {
			title: 'Crecimiento del Grupo',
			mindset: [
				'Quiero sentir que tengo la oportunidad de revitalizar mi núcleo.',
				'Integro la familia de mis hijos o los hijos de mi pareja',
				'Hemos construido unas reglas claras en el espacio de mi cuarto y mi cama'
			],
			necesities: [
				'Encontrar un asesor de confianza que se vuelva asesor de todos los miembros de mi grupo',
				'Comprobar que los detalles son coherentes con la percepción de calidad de la marca',
				'Asesoría hacia otros miembros de la familia'
			],
			situations: [
				'Un espacio para los mios',
				'Más hijos en la familia',
				'Base de operaciones familiares',
				'Familias extensans: mis hijos, tus hijos y los nuestros'
			],
			drivers: [
				'Firmeza consensuada',
				'Accesorios de lencería',
				'Ortopédico',
				'Percepción de costo-beneficio',
				'Entretenimiento'
			],
			emotionals: [
				'Base de unión familiar',
				'Una manera de expresar amor',
				'Proteger el desarrollo de mis hijos',
				'Grande para que quepan todos',
				'La elegancia que me he ganado'
			]
		}
	}

	return (
		<Drawer
			visible={visible}
			onClose={onClose}
			title={slug && profiles[slug].title}
			width={400}
		>
			<h6 className="mb-3">Minset de Vida</h6>
			<ul className="pl-3">
				{ slug && profiles[slug].mindset.map((val, i) => (
					<li className="mb-1" key={i}>{val}</li>
				))}
			</ul>
			<h6 className="mb-3">Necesidades Frente a la Categoría</h6>
			<ul className="pl-3">
				{ slug && profiles[slug].necesities.map((val, i) => (
					<li className="mb-1" key={i}>{val}</li>
				))}
			</ul>
			<h6 className="mb-3">Situaciones</h6>
			<ul className="pl-3">
				{ slug && profiles[slug].situations.map((val, i) => (
					<li className="mb-1" key={i}>{val}</li>
				))}
			</ul>
			<h6 className="mb-3">Motivador de Compra</h6>
			<ul className="pl-3">
				{ slug && profiles[slug].drivers.map((val, i) => (
					<li className="mb-1" key={i}>{val}</li>
				))}
			</ul>
			<h6 className="mb-3">Motivador Emocional</h6>
			<ul className="pl-3">
				{ slug && profiles[slug].emotionals.map((val, i) => (
					<li className="mb-1" key={i}>{val}</li>
				))}
			</ul>
		</Drawer>
	)
}