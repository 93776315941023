import springApi from '../../services/springApi'

export const getProfiles = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/profiles', {params})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getProfile = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/profiles/${id}`, {params})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeProfile = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/profiles', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)