import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap'
import { Spin } from 'antd'
import moment from 'moment'

import QuoteLeadDetails from './partials/QuoteLeadDetails'
import QuoteProductsDetails from './partials/QuoteProductsDetails'
import QuoteExtraDetails from './partials/QuoteExtraDetails'
import RankingDrawersCard from './partials/RankingDrawersCard'

import { createQuote } from '../Quotes/services'
import { handleError } from '../../helpers'

export default function NewQuoteScreen() {
	const user = useSelector(state => state.auth.details)
	const [leadId, setLeadId] = useState(null)
	const [expiredAt, setExpiredAt] = useState(moment().endOf('month').format('YYYY-MM-DD'))
	const [comment, setComment] = useState('')
	const [extraUserSelected, setExtraUserSelected] = useState([])
	const [isSubmited, setIsSubmited] = useState(false)
	const [groups, setGroups] = useState([1])
	const [quoteId, setQuoteId] = useState(null)

	useEffect(()=>{
		isSubmited && leadId && expiredAt && !quoteId && createQuote({
			user_id: user.id,
			store_id: user.current_store,
			lead_id: leadId,
			expire_at: expiredAt,
			comment,
			extra_user_id: extraUserSelected ? extraUserSelected.value : ''
		})
			.then(res => setQuoteId(res.data.data.id))
			.catch(error => {
				handleError(error)
				setIsSubmited(false)
			})
	}, [ isSubmited, leadId, expiredAt, quoteId, user, comment, extraUserSelected ])

	const onNewGroup = () => {
		setGroups([ ...groups, (Math.max(...groups) + 1) ])
	}

	return (
		<React.Fragment>
			<Row>
				<Col md="8">					
					<Card>
						<CardBody>
							<div className="float-right pt-2">
								<Button 
									color="primary" 
									disabled={isSubmited} 
									onClick={onNewGroup}
								>
									Añadir Opción
								</Button>
							</div>
							<CardTitle>Nueva Cotización</CardTitle>
							<CardSubtitle>
								Cotización en tienda{" "}
								<span className="text-primary">{user.active_store.code} - {user.active_store.name}</span>
							</CardSubtitle>							
						</CardBody>
					</Card>
					<Spin spinning={isSubmited}>
						{ groups.map( (group, i) =>
							<QuoteProductsDetails
								key={group}
								groupId={group}
								quoteId={quoteId}
								isSubmited={isSubmited}
								setIsSubmited={setIsSubmited}
								isLastGroup={i === groups.length-1 ? true : false}
								handleDelete={groups.length > 1 ? 
									() => setGroups( groups.filter(gp => gp !== group) ) : null
								}
							/>
						)}
					</Spin>
					<RankingDrawersCard />
				</Col>
				<Col md="4">
					<QuoteLeadDetails
						isSubmited={isSubmited}
						setIsSubmited={setIsSubmited}
						leadId={leadId}
						setLeadId={setLeadId}
					/>
					<QuoteExtraDetails 
						store={user.active_store}
						expiredAt={expiredAt}
						setExpiredAt={setExpiredAt}
						isSubmited={isSubmited}
						setIsSubmited={setIsSubmited}
						comment={comment}
						setComment={setComment}
						extraUserSelected={extraUserSelected}
						setExtraUserSelected={setExtraUserSelected}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}