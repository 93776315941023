import { getDomain, springApi } from '../../services/springApi'

export const deliveryEstimate = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/delivery_estimate', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getProductPromises = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/product_promises', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchCities = async (term, token) => (
	fetch( getDomain()+`/city_routes?filter[city]=${term}&sort=city`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(cityroute => ({
				label: cityroute.city,
				value: cityroute.id,
			})),
		)
)