import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Card, CardBody, CardTitle, CardSubtitle, Spinner } from 'reactstrap'
import { Divider } from 'antd'

export default function QuoteCategoriesWidget({ report }) {
	
	const data = report ? {
		labels: ['Solo Colchones', 'Solo Complementarios', 'Ambas Categorías'],
		datasets: [
		  {
			 label: '# cotizaciones',
			 data: [report.categories.solo_colchon, report.categories.solo_otros, report.categories.ambos],
			 backgroundColor: [
				'rgba(54, 162, 235, 0.8)',
				'rgba(255, 206, 86, 0.8)',
				'rgba(75, 192, 192, 0.8)',

			 ],
			 borderColor: [
				'rgba(54, 162, 235, 1)',
				'rgba(255, 206, 86, 1)',
				'rgba(75, 192, 192, 1)',
			 ],
			 borderWidth: 1,
		  },
		],
	} : {}
	 
	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Reporte por Categorías</CardTitle>
					<CardSubtitle>Cotizaciones con productos de categoría</CardSubtitle>
					<Divider className="mt-2 mb-3" />
					{ report ? <Doughnut data={data} /> : <Spinner size="sm" />}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}