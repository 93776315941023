import { 
   USER_LOGIN, 
   USER_LOGOUT,
   USER_NEW_STORE
} from "./actions"

const INITIAL_STATE = {
   token: null
}

const authReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case USER_LOGIN:
         return { ...state, ...action.payload }

      case USER_LOGOUT:
         return INITIAL_STATE

      case USER_NEW_STORE:
            return { ...state, details: action.payload}
         
      default:
         return { ...state }
   }
}

export default authReducer