import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import { currency, roundZero } from '../../../helpers'

export default function CustomRateModal({ visible, onClose, itemIndex, items, setItems }) {
    const { register, handleSubmit, errors } = useForm()
    const [loading, setLoading] = useState(false)

    const onSubmit = values => {
        setLoading(true)
        let newItems = [...items]
        newItems[itemIndex].rate.before_edit_price = newItems[itemIndex].rate.net_price
        newItems[itemIndex].rate.net_price = parseInt(values.net_price)
        newItems[itemIndex].rate.discount = roundZero((newItems[itemIndex].rate.gross_price - newItems[itemIndex].rate.net_price) / newItems[itemIndex].rate.gross_price * 100)
        setItems(newItems)
        onClose()
    }

    return (
        <Modal isOpen={visible} toggle={onClose}>
            <ModalHeader toggle={onClose}>
                Modificar Valor de Tarifa {items[itemIndex].product.name}
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className='form-group'>
                        <Form.Label>Valor de Tarifa por Unidad <span className='text-danger'>*</span></Form.Label>
                        <Form.Control 
                            type="number"
                            ref={register({ required:true, min:0 })}
                            name="net_price"
                            defaultValue={items[itemIndex].rate.net_price}
                        />
                        <Form.Text className='text-hint'>
                            Tarifa actual ${currency(items[itemIndex].rate.net_price)} COP{" "}
                            { items[itemIndex].rate.before_edit_price && `Precio antes de modificar: $${currency(items[itemIndex].rate.before_edit_price)} COP` }
                        </Form.Text>
                        { errors.net_price && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                    </Form.Group>
                    <Button type="submit" color='primary' disabled={loading}>
                        Modificar Valor
                    </Button>
                </Form>
            </ModalBody>
        </Modal>
    )
}