import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, InputGroup } from 'react-bootstrap'
import { Alert, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { message } from 'antd'

import { getEmployeeCoupons } from '../services'
import { currency, handleError } from '../../../helpers'
import CouponOrderInvoiceModal from './CouponOrderInvoiceModal'

export default function EmployeeCouponsWidget() {
	const { handleSubmit, register, formState: { errors } } = useForm()
	const [employeeCoupons, setEmployeeCoupons] = useState(null)
	const [loading, setLoading] = useState(false)
	const [orderInvoiceModal, setOrderInvoiceModal] = useState(null)

	const onSubmit = values => {
		setLoading(true)
		getEmployeeCoupons(values)
			.then(res => {
				message.info(res.data.message)
				setEmployeeCoupons(res.data.data)
			})
			.catch(handleError)
			.finally(() => setLoading(false))
	}

	const handleChange = () => {
		setLoading(null)
		setEmployeeCoupons(null)
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<InputGroup>
						<InputGroup.Text>Cédula Empleado</InputGroup.Text>
						<Form.Control 
							name="document"
							type="number"
							ref={register({ required:true })}
							placeholder="Escriba aquí..."
							onChange={handleChange}
						/>
						<Button color="primary" type="submit" disabled={loading}>
							Consultar
						</Button>
					</InputGroup>
					{ errors.document && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Form>
			{ employeeCoupons && (
				<React.Fragment>
					{ employeeCoupons.length > 0 ? (
						<React.Fragment>
							<Alert color="success" className='mb-0'>
								<p className='bold'>Cupos autorizados para uso en tiendas:</p>
								{employeeCoupons.map(c => (
									<div key={c.coupon_order_id} className="p-2 bg-light mb-10">
										<div className='float-right'>
											<Button
												color="primary"
												onClick={() => setOrderInvoiceModal(c.coupon_order_id)}
											>
												Registrar Uso
											</Button>
										</div>
										<p className='mb-0'><b>CUPO:</b> {c.coupon_sku}</p>
										<p className='mb-0'><b>TIPO:</b> {c.coupon_type}</p>
										<p className='mb-0'>
											<b>PAGO:</b> {c.payment_method === 'salary' ? 'Nomina' : 'De contado'}
											{" | "}
											<b>CUOTAS:</b> {c.payment_method === 'salary' ? `${c.credit_payments} cuotas` : 'No aplica'}
										</p>
										<p className='mb-0'><b>PRODUCTO AUTORIZADO:</b> {c.product_desc ? c.product_desc : <em>Sin específicar</em>}</p>
										<p className='mb-0'><b>TARIFA EMPLEADO (IVA incluido):</b> {c.employee_price ? `$ ${currency(c.employee_price)} COP` : <em>Sin específicar</em>}</p>
									</div>
								))}
							</Alert>
						</React.Fragment>
					) : (
						<Alert color='warning' className='mb-0'>
							<FontAwesomeIcon icon={faExclamationTriangle} />{" "}
							No se encontraron cupos autorizados para su uso.
						</Alert>
					)}
				</React.Fragment>
			)}
			{ orderInvoiceModal && (
				<CouponOrderInvoiceModal 
					visible
					onClose={() => setOrderInvoiceModal(null)}
					couponOrderId={orderInvoiceModal}
					reload={handleChange}
				/>
			)}
		</React.Fragment>
	)
}