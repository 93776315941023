import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import LeadProfileForm from '../../Profiles/partials/LeadProfileForm'
import { storeProfile } from '../../Profiles/services'
import { handleError } from '../../../helpers'
import { updateQuote } from '../../Quotes/services'
import { storeVisit } from '../../Visits/services'

export default function ConfirmModal({ visible, onClose, quoteId }) {
	const { handleSubmit, register, errors } = useForm()
	const history = useHistory()

	const onSubmit = values => {
		storeProfile(values)
			.then(res => updateQuote(quoteId, { profile_id: res.data.data.id }))
			.then(res => storeVisit({
				user_id: res.data.data.user_id,
				store_id: res.data.data.store_id,
				profile_id: res.data.data.profile_id,
				quote_id: res.data.data.id,
			}))
			.then(() => {
				message.success('Cotización creada exitosamente')
				history.push(`/cotizaciones/${quoteId}`)
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ModalBody>
						<LeadProfileForm 
							register={register}
							errors={errors}
						/>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" type="submit">
							Guardar y Finalizar
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		</React.Fragment>
	)
}