import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { message, Modal } from 'antd'
import { Form } from 'react-bootstrap'

import { updateLead } from '../services'
import { handleError } from '../../../helpers'

export default function EditLeadModal({ visible, onClose, lead, reload }) {
	const [loading, setLoading] = useState(false)
	const { register, errors, trigger, getValues, reset } = useForm()
	
	const handleSubmit = async () => {
		const isFormValid = await trigger()

		if (isFormValid) {
			setLoading(true)
			const values = getValues()

			updateLead(lead.id, values)
				.then(() => {
					message.success('Datos actualizados exitosamente')
					reset()
					reload()
					onClose()
				})
				.catch(error => handleError(error))
		}
	}
	
	return (
		<Modal
			visible={visible}
			onCancel={onClose}
			onOk={handleSubmit}
			okText="Actualizar Datos"
			cancelText="Cancelar"
			confirmLoading={loading}
			okType="primary"
		>
			<Form.Group>
				<Form.Label>Nombre Completo <span className="text-primary">*</span></Form.Label>
				<Form.Control
					name="name"
					placeholder="Escriba el nombre completo"
					defaultValue={lead.name}
					ref={register({ required:true })}
				/>
				{ errors.name && <Form.Text className="text-warning">Ingrese el nombre completo</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Teléfono Celular <span className="text-primary">*</span></Form.Label>
				<Form.Control
					name="mobile"
					placeholder="Escriba el celular"
					type="number"
					defaultValue={lead.mobile}
					ref={register({ required:true, minLength: 10, maxLength: 10 })}
				/>
				{ errors.mobile && <Form.Text className="text-warning">Ingrese un celular válido de 10 digitos</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Cédula del contacto <small>(opcional)</small></Form.Label>
				<Form.Control
					name="document"
					type="number"
					placeholder="Escriba el documento aquí..."
					defaultValue={lead.document}
					ref={register({ })}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Correo Electrónico <small>(opcional)</small></Form.Label>
				<Form.Control
					name="email"
					type="email"
					placeholder="Escriba un correo válido"
					defaultValue={lead.email}
					ref={register({ })}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Teléfono Secundario <small>(opcional)</small></Form.Label>
				<Form.Control
					name="phone"
					type="number"
					placeholder="Escriba un télefono válido"
					defaultValue={lead.phone}
					ref={register({ })}
				/>
			</Form.Group>
		</Modal>
	)
}