import React from 'react'
import { Drawer } from 'antd'

export default function GenerationInsights({ visible, onClose, slug }) {
	
	const generations = {
		millennials: {
			title: '20 a 40 años - Milennials',
			who: [
				'Valoran la libertad y la flexibilidad',
				'Orientados a la expresión personal',
				'Inmersos el lo digital no se conciben sin tecnología',
				'Encontra de las jerarquías y la verticalidad'
			],
			what: [
				'Buscan contenidos e ideología de la marca',
				'Prefieren las empresas activas en redes sociales',
				'Le dan valor a la responsabilidad social de las marcas'
			]
		},
		genx: {
			title: '40 a 65 años - Generación X',
			who: [
				'Necesitan mediar entre lo laboral y lo personal',
				'No quieren ser etiquetados como sus padres',
				'Valoran el progreso y la realización material',
				'Altamente adaptable a los cambios y las nuevas tendencias'
			],
			what: [
				'Soluciones personalizadas',
				'Gran sensación de ganancia',
				'Mayor atención a los detalles'
			]
		},
		boomers: {
			title: '65 a 83 años - Baby Boomers',
			who: [
				'Orientados a la familia',
				'Buscan relaciones cara a cara',
				'Motivados por la posición económica',
				'Valoran relaciones verticales'
			],
			what: [
				'Información clara y precisa',
				'Sentirse respaldados por sus familias',
				'Valoran el respaldo y la tradición de las marcas'
			]
		},
	}
	
	return (
		<React.Fragment>
			<Drawer
				visible={visible}
				onClose={onClose}
				width={400}
				title={slug && generations[slug].title}
			>
				<h6 className="mb-3">¿Quienes son?</h6>
				<ul className="pl-3">
					{slug && generations[slug].who.map( (item, i) => <li className="mb-2" key={i}>{item}</li> )}
				</ul>
				<h6 className="mb-3">¿Qué buscan en la compra de colchones?</h6>
				<ul className="pl-3">
					{slug && generations[slug].what.map( (item, i) => <li className="mb-2" key={i}>{item}</li> )}
				</ul>
			</Drawer>
		</React.Fragment>
	)
}