import springApi from '../../services/springApi'

export const getRates = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/rates', {params})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const syncAndGetRates = (storeId, productId) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/rates/sg/${storeId}/${productId}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)