import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, InputGroup } from 'react-bootstrap'
import { Alert, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { message } from 'antd'

import ReferralOrderInvoiceModal from './ReferralOrderInvoiceModal'

import { getReferralIncentives } from '../services'
import { handleError } from '../../../helpers'

export default function ReferralIncentivesWidget() {
	const { handleSubmit, register, formState: { errors } } = useForm()
	const [referralIncentives, setReferralIncentives] = useState(null)
	const [loading, setLoading] = useState(false)
	const [orderInvoiceModal, setOrderInvoiceModal] = useState(null)

	const onSubmit = values => {
		setLoading(true)
		getReferralIncentives(values)
			.then(res => {
				message.info(res.data.message)
				setReferralIncentives(res.data.data)
			})
			.catch(handleError)
			.finally(() => setLoading(false))
	}

	const handleChange = () => {
		setLoading(null)
		setReferralIncentives(null)
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<InputGroup>
						<InputGroup.Text>Cédula Referido</InputGroup.Text>
						<Form.Control 
							name="document"
							type="number"
							ref={register({ required:true })}
							placeholder="Escriba aquí..."
							onChange={handleChange}
						/>
						<Button color="primary" type="submit" disabled={loading}>
							Consultar
						</Button>
					</InputGroup>
					{ errors.document && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Form>
			{ referralIncentives && (
				<React.Fragment>
					{ referralIncentives.length > 0 ? (
						<React.Fragment>
							<Alert color="success" className='mb-0'>
								<p className='bold'>Incentivos autorizados para uso en tiendas:</p>
								{referralIncentives.map(c => (
									<div key={c.referral_order_id} className="bg-light p-2 mb-10">
										<div className='float-right'>
											<Button
												color="primary"
												onClick={() => setOrderInvoiceModal(c.referral_order_id)}
											>
												Registrar Venta
											</Button>
										</div>
										{c.incentive_name} ({c.incentive_terms}) <br/>
										<em>Referido por: {c.employee_name}</em>
									</div>
								))}
							</Alert>
						</React.Fragment>
					) : (
						<Alert color='warning' className='mb-0'>
							<FontAwesomeIcon icon={faExclamationTriangle} />{" "}
							No se encontraron incentivos autorizados para su uso.
						</Alert>
					)}
				</React.Fragment>
			)}
			{ orderInvoiceModal && (
				<ReferralOrderInvoiceModal 
					visible
					onClose={() => setOrderInvoiceModal(null)}
					referralOrderId={orderInvoiceModal}
					reload={() => handleChange()}
				/>
			)}
		</React.Fragment>
	)
}