import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardTitle, CardHeader } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import NewStockAlertForm from './partials/NewStockAlertForm'
import StockAlertsTable from './partials/StockAlertsTable'

import { getStockAlerts } from './services'
import { handleError } from '../../helpers'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'

export default function StockAlertsList() {
	const user = useSelector(state => state.auth.details)
	const [stockAlerts, setStockAlerts] = useState(null)
	const [filterType, setFilterType] = useState('product.name')
	const [filterValue, setFilterValue] = useState('')
	const [onlyActive, setOnlyActive] = useState(true)
	const [onlyCurrent, setOnlyCurrent] = useState(false)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!stockAlerts && getStockAlerts({
			include: 'product',
			[`filter[${filterType}]`]: filterValue,
			'filter[store_id]': user.current_store,
			'filter[active]': onlyActive ? 1 : null,
			'filter[current]': onlyCurrent ? 1 : null,
			sort: '-updated_at',
			...pagination
		})
			.then(res => setStockAlerts(res.data.data))
			.catch(error => handleError(error))
	}, [ stockAlerts, filterType, filterValue, pagination, user, onlyActive, onlyCurrent ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title={`Alertas de Inventario - Tienda ${user.active_store.code}`}
				subtitle="Lista de alertas de inventario activas"
				reload={() => setStockAlerts(null)}
				options={[
					{ label: 'Buscar por nombre producto', value: 'product.name' },
					{ label: 'Buscar por referencia de producto', value: 'product.reference' },
				]}
			/>
			<Card>
				<CardBody>
					<Form.Check
						label="Mostrar solo alertas sin resolver" 
						checked={onlyActive}
						onChange={() => {
							setOnlyActive(!onlyActive)
							setStockAlerts(null)
						}}
						className='block mb-2'
					/>
					<Form.Check
						label="Mostrar solo alertas de hoy" 
						checked={onlyCurrent}
						onChange={() => {
							setOnlyCurrent(!onlyCurrent)
							setStockAlerts(null)
						}}
						className='block mb-2'
					/>
					<StockAlertsTable 
						stockAlerts={stockAlerts}
						reload={() => setStockAlerts(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			<Card>
				<CardHeader>
					<CardTitle>{`Nueva Alerta de Inventario - Tienda ${user.active_store.code}`}</CardTitle>
				</CardHeader>
				<CardBody>
					<NewStockAlertForm reload={() => setStockAlerts(null)} />
				</CardBody>
			</Card>
		</React.Fragment>
	)
}