import springApi from '../../services/springApi'

export const getQuotes = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/quotes', {params})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getQuote = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/quotes/${id}`, {params})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const createQuote = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/quotes', data)	
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateQuote = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/quotes/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)


export const assignProductToQuote = (quoteId, productId, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/quotes/${quoteId}/products/${productId}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteProductQuote = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/product_quote/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getPublicQuote = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/public/quotes/${id}`, {params})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeQuoteOpen = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/public/quote_opens', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeQuoteState = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post(`/quote_states`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const markAsWon = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/quote_states/won', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const notifyQuote = id => (
	new Promise((resolve, reject) => {
		springApi()
			.post(`/quotes/notify/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)