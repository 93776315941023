import React from "react"
import {useSelector} from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import Authentication from './containers/Authentication/'
import Error404 from './containers/ErrorsPages/Error404'
import Quoting from "./containers/Quoting"
import Quotes from "./containers/Quotes"
import Leads from "./containers/Leads"
import Search from "./containers/Search"
import Reports from "./containers/Reports"
import QuotePreview from "./containers/Quotes/QuotePreview"
import VisitsProfiling from "./containers/Visits/VisitsProfiling"
import Deliveries from "./containers/Deliveries"
import StockAlerts from "./containers/StockAlerts"
import EmployeeSales from "./containers/EmployeeSales"

import MainLayout from "./layouts/MainLayout"

export default function App() {	
	const token = useSelector(state => state.auth.token)
	
	return (
		<React.Fragment>
			<Switch>
				<Redirect exact path="/" to="/cotizador" />
				<Route exact path="/v/:id" component={QuotePreview} />

				<Route path="/auth" component={Authentication} />
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>
					<Route path="/cotizador" component={Quoting} />
					<Route path="/cotizaciones" component={Quotes} />
					<Route path="/prospectos" component={Leads} />
					<Route path="/buscar" component={Search} />
					<Route path="/reportes" component={Reports} />
					<Route path="/entregas" component={Deliveries} />
					<Route path="/alertas_inventario" component={StockAlerts} />
					<Route path="/venta_empleados" component={EmployeeSales} />
					
					<Route exact path="/perfil" component={VisitsProfiling} />
				</MainLayout>

				<Route component={Error404} />
			</Switch>
		</React.Fragment>
	);	
}