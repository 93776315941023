import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { Divider, Drawer, Empty } from 'antd'
import moment from 'moment'

import { getQuoteOpens } from './services'
import { handleError } from '../../helpers'

export default function RecentOpensDrawer({ visible, onClose }) {
	const user = useSelector(state => state.auth.details)
	const [opens, setOpens] = useState(null)

	useEffect(() => {
		!opens && getQuoteOpens({ 
			'filter[quote.user_id]': user.id, 
			per_page: 20,
			sort: '-created_at'
		})
			.then(res => setOpens(res.data.data))
			.catch(handleError)
	}, [ opens, user ])

	return (
		<React.Fragment>
			<Drawer
				visible={visible}
				onClose={onClose}
				title="Aperturas recientes"
				width={"400px"}
			>
				{ opens ? (
					<React.Fragment>
						{ opens.length > 0 ? (
							<React.Fragment>
								{ opens.map(open => (
									<div className='mb-1 bg-light p-1' key={`open_${open.id}`}>
										<p className='mb-0'>{open.lead_name} abrio la cotización #{open.quote_number}</p>
										<small>{moment(open.created_at).fromNow()}</small>
										<Divider type='vertical' />
										<Link to={`/cotizaciones/${open.quote_id}`} onClick={onClose}>
											<small>Ver cotización</small>
										</Link>
									</div>
								)) }
							</React.Fragment>
						) : (
							<Empty description="No tiene aperturas registradas" />
						)}
					</React.Fragment>
				) : (
					<Spinner color='primary' />
				)}
			</Drawer>
		</React.Fragment>
	)
}