import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'

import LeadProfileForm from '../../Profiles/partials/LeadProfileForm'
import { Button } from 'reactstrap'
import { storeProfile } from '../../Profiles/services'
import { handleError } from '../../../helpers'
import { storeVisit } from '../services'
import { message } from 'antd'

export default function NewVisitForm() {
	const user = useSelector(state => state.auth.details)
	const { handleSubmit, register, errors, setValue } = useForm()
	const [result, setResult] = useState('')

	const onSubmit = values => {
		storeProfile(values)
			.then(res => res.data.data)
			.then(profile => storeVisit({
				user_id: user.id,
				store_id: user.current_store,
				profile_id: profile.id,
				...values
			}))
			.then(() => {
				message.success('Visita registrada exitosamente')
				setValue('gender', '')
				setValue('age_range', '')
				setValue('invoice_number', '')
				setValue('suggested_products', '')
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<LeadProfileForm 
					register={register} 
					errors={errors} 
				/>
				<Form.Group>
					<Form.Label>Resultado de Visita</Form.Label>
					<Form.Control
						as="select"
						value={result}
						onChange={e => setResult(e.target.value)}
					>
						<option value="invoice">Realiza compra sin cotizar</option>
						<option value="">No cotiza ni compra</option>
					</Form.Control>
				</Form.Group>
				{ result === 'invoice' && (
					<Form.Group>
						<Form.Label>Número de Factura</Form.Label>
						<Form.Control 
							name="invoice_number"
							ref={register({required:true})}
							placeholder="S00-10000"
						/>
						{ errors.invoice_number && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
				)}
				<Form.Group>
					<Form.Label>Motivo de Visita</Form.Label>
					<Form.Control
						as="select"
						name="suggested_category"
						ref={register({required:true})}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option>Productos que NO comercializamos</option>
						<option>Tiempo de entrega</option>
						<option>Garantías o devoluciones</option>
						<option>Producto no disponible</option>
						<option>Producto sin inventario</option>
						<option>Por curiosidad</option>
						<option>Regresa con cotización</option>
						<option>Cotización de otra tienda</option>
						<option>Cotización tienda online Spring</option>
					</Form.Control>
					{ errors.suggested_category && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					<Form.Control 
						as="textarea"
						name="suggested_products"
						placeholder="Escriba sus observaciones."
						ref={register({required:true})}
					/>
					{ errors.suggested_products && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Button color="primary" type="submit">
					Registrar Visita
				</Button>
			</Form>
		</React.Fragment>
	)
}