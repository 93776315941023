import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { Spinner } from 'reactstrap'
import { Drawer, Empty } from 'antd'

import { mostQuotedProducts } from '../services'
import { handleError } from '../../../helpers'
import { Card, CardBody } from 'reactstrap'

export default function MostQuotedDrawer({ visible, onClose }) {
	const user = useSelector(state => state.auth.details)
	const [products, setProducts] = useState(null)
	const [category, setCategory] = useState('COLCHONES')

	useEffect(() => {
		!products && visible && mostQuotedProducts({
			store_id: user.current_store,
			category
		})
			.then(res => setProducts(res.data.data))
			.catch(error => handleError(error))
	}, [ products, visible, user, category ])

	return (
		<React.Fragment>
			<Drawer
				visible={visible}
				onClose={() => {
					onClose()
					setProducts(null)
				}}
				title="Productos más cotizados"
				width={450}
			>
				<Form.Group className="mb-1">
					<Form.Control 
						as="select" 
						className="mr-2"
						value={category}
						onChange={e => {
							setCategory(e.target.value)
							setProducts(null)
						}}
					>
						<option>COLCHONES</option>
						<option>LENCERIA</option>
						<option>ALMOHADAS</option>
						<option>MUEBLES</option>
						<option>BASES Y PATAS</option>
						<option>CAMAS</option>
						<option>ACCESORIOS</option>
						<option>COMBO</option>
						<option>BONOS</option>
						<option value="CONCEPTOS ADMINISTRATIVOS">OTROS</option>
					</Form.Control>
				</Form.Group>
				<Form.Group className="mb-1">
					<Form.Control 
						value={`${user.active_store.code} - ${user.active_store.name}`}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Form.Control value="Según los últimos 90 días" disabled />
				</Form.Group>
				{ products ? (
					<div className="bg-light p-2">
						{ products.length > 0 ? (
							<React.Fragment>
								{ products.map( (product, i) => (
									<Card key={product.id} className="mb-2">
										<CardBody className="py-2">
											<p className="bold mb-0">#{i+1} - {product.name}</p>
											<p className="hint-text mb-0">
												{product.color} - {product.size}
											</p>
											<p className="text-primary mb-0">
												{product.quantity} unidades cotizadas
											</p>
										</CardBody>
									</Card>
								)) }
							</React.Fragment>
						) : <Empty description="No se encontraron productos" />}
					</div>
				) : (
					<Spinner className="mt-2" />
				)}
			</Drawer>
		</React.Fragment>
	)
}