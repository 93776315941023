import springApi from '../../services/springApi'

export const getStockAlerts = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/stock_alerts', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getStockAlert = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/stock_alerts/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeStockAlert = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/stock_alerts', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateStockAlert = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/stock_alerts/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteStockAlert = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/stock_alerts/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)