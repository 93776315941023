import React from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { markAsWon } from '../services'
import { handleError } from '../../../helpers'

export default function LinkInvoiceModal({ 
	visible, 
	onClose, 
	quoteId, 
	status, 
	handleSuccess, 
	statusList, 
	selectedStatusIndex 
}) {
	const { handleSubmit, register, errors } = useForm()

	const onSubmit = values => {
		values.quote_id = quoteId
		values.status = status
		markAsWon(values)
			.then(()=>handleSuccess())
			.catch(error => handleError(error))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Asociar Factura con Cotización</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>					
					<Form.Group>
						<Form.Label>Número de Factura</Form.Label>
						<Form.Control
							name="invoice_number"
							placeholder="S20F1234"
							ref={register({ required: true })}
						/>
					</Form.Group>
					{ errors.term && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
					{ statusList[ selectedStatusIndex ] && statusList[ selectedStatusIndex ].options && (
						<Form.Group>
							<Form.Label>Estado Adicional <span className="text-primary">*</span></Form.Label>
							<Form.Control
								as="select"
								name="secondary_status"
								ref={register({ required:true })}
							>
								{ statusList[ selectedStatusIndex ].options.map((status,i) => (
									<option key={i}>{status}</option>
								)) }
							</Form.Control>
						</Form.Group>
					)}
					<Form.Group>
						<Form.Label>Comentario <span className="text-primary">*</span></Form.Label>
						<Form.Control 
							as="textarea"
							name="comment"
							ref={register({ required: true })}
						/>
						{ errors.comment && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
					</Form.Group>
					<Button color="primary">Marcar como cerrada en Spring</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}