import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import moment from 'moment'
import 'moment/locale/es'

import Pagination from '../../../components/Pagination'

export default function LeadsTable({ leads, pagination, setPagination, reload }) {

	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'name',
		},
		{
			title: 'Cédula',
			dataIndex: 'document',
		},
		{
			title: 'Celular',
			dataIndex: 'mobile',
		},
		{
			title: 'Correo Electrónico',
			dataIndex: 'email',
		},
		{
			title: 'Última Cotización',
			dataIndex: 'latest_quote',
			render: t => (
				<Link to={`/cotizaciones/${t.id}`}>
					{moment(t.created_at).format('YYYY-MMM-DD hh:mm a')}
				</Link>
			)
		},
		{
			title: 'Total Cotizaciones',
			dataIndex: 'quotes_count',
			render: t => `${t} en total`
		}
	]

	return (
		<React.Fragment>
			<Table
				dataSource={leads}
				rowKey={record => record.id}
				columns={columns}
				pagination={false}
				size="small"
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}	
			/>
		</React.Fragment>
	)
}