import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Card, CardBody, Button, CardFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { message } from 'antd'

import AddProductModal from './AddProductModal'
import SuggestsCard from './SuggestsCard'
import ProductItemsList from './ProductItemsList'
import ConfirmModal from './ConfirmModal'

import { assignProductToQuote } from '../../Quotes/services'
import { handleError } from '../../../helpers'

function QuoteProductsDetails({ groupId, handleDelete, isSubmited, setIsSubmited, quoteId, isLastGroup, history }) {
	const [showAddProductModal, setShowAddProductModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const [items, setItems] = useState([])
	const [defaultCategory, setDefaultCategory] = useState('COLCHONES')
	const [defaultParentKey, setDefaultParentKey] = useState(null)
	const [showConfirmModal, setShowConfirmModal] = useState(false)

	useEffect(()=>{
		if(!isSubmited && loading) setLoading(false)
	}, [loading, isSubmited])

	useEffect(() => {
		const onSubmit = () => {
			setLoading(true)

			if(items.length === 0){
				setIsSubmited(false)
				message.warning(`La opción #0${groupId} no tiene productos`)
			}
		}

		isSubmited && !loading && onSubmit()
	}, [ isSubmited, loading, setIsSubmited, handleDelete, items, groupId ])

	useEffect(()=>{
		const handleStore = async () => {
			items.map(async (item, index) => {
				assignProductToQuote(quoteId, item.product.id, {
					group_index: groupId,
					quantity: item.quantity,
					gross_price: item.rate.gross_price,
					net_price: item.rate.net_price,
					discount: item.rate.discount,
					rate_code: item.rate.code,
					rate_desc: item.rate.description,
					before_edit_price: item.rate.before_edit_price,
				})
					.then(() => {
						if( isLastGroup && index === items.length-1 ){
							setShowConfirmModal(true)
						}
					})
					.catch(error => {
						handleError(error)
						setIsSubmited(false)
					})
			})
		}
		
		isSubmited && quoteId && handleStore()
	}, [ isSubmited, quoteId, items, groupId, setIsSubmited, isLastGroup, history ])

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<p className="small mb-2 bold">Opción #0{groupId}</p>
					{ items.length > 0 ? (
						<React.Fragment>
							<ProductItemsList 
								items={items} 
								setItems={setItems} 
								handleAddItem={()=>setShowAddProductModal(true)}
								handleChangleItem={parentKey => {
									setDefaultParentKey(parentKey)
									setShowAddProductModal(true)
								}}
							/>							
						</React.Fragment>
					) : (
						<EmptyCard 
							handleAddItem={()=>setShowAddProductModal(true)} 
							handleDelete={handleDelete} 
						/>
					)}
				</CardBody>
				<CardFooter className="bg-white">
					<SuggestsCard handleClick={category => {
						setDefaultCategory(category)
						setShowAddProductModal(true)
					}}/>
				</CardFooter>
			</Card>
			{ showAddProductModal && (
				<AddProductModal
					visible
					handleClose={() => {
						setShowAddProductModal(false)
						setDefaultCategory('COLCHONES')
						setDefaultParentKey(null)
					}}
					items={items}
					setItems={setItems}
					defaults={{ 
						category: defaultCategory,
						parent_key: defaultParentKey
					}}
				/>
			)}
			{ showConfirmModal && (
				<ConfirmModal 
					visible
					onClose={() => setShowConfirmModal(false)}
					quoteId={quoteId}
				/>
			)}
		</React.Fragment>
	)
}

export const EmptyCard = ({ handleAddItem, handleDelete }) => (
	<div className="bg-light p-4 text-center">
		<FontAwesomeIcon icon={faArchive} size="2x" className="text-muted mt-2" />
		<h6 className="text-muted mb-0">No tiene productos</h6>
		<p>Agregue los productos que desea cotizar</p>
		<div>
			<Button color="primary" className="mr-2" onClick={handleAddItem}>
				<FontAwesomeIcon icon={faPlus} size="xs" /> Agregar Producto
			</Button>
			{handleDelete && <Button color="primary" outline onClick={handleDelete}>
				<FontAwesomeIcon icon={faTrash} size="xs" /> Eliminar Opción
			</Button>}
		</div>
	</div>
)

export default withRouter(QuoteProductsDetails)