import React from 'react'
import { Descriptions, Empty } from 'antd'
import moment from 'moment'
import 'moment/locale/es'
import { currency } from '../../../helpers'

const { Item } = Descriptions

export default function SimulatorResults({ report }) {
	return (
		<React.Fragment>
			{ report ? (
				<React.Fragment>
					<Descriptions bordered column={4} className="bg-white">
						<Item label="Fecha de Entrega" span={4}>
							Entrega del <span className="text-primary bold">{moment(report.delivery_date).format('dddd DD-MMM-YYYY')}</span> al {moment(report.delivery_date).add(3,'day').format('dddd DD-MMM-YYYY')}
						</Item>						
						<Item label="Tarifas" span={4}>
							{ report.city_route_rates.length > 0 ? (
								<React.Fragment>
									{ report.city_route_rates.map( (rate, i) => (
										<p><strong>Tarifa {i+1}: </strong> ${currency(rate.value)} <small>({rate.description})</small></p>
									)) }
								</React.Fragment>
							) : '$ 0'}
						</Item>
						<Item label="Pago Contra Entrega" span={2}>{report.city_route.pay_on_delivery ? 'Si' : 'No'}</Item>
						<Item label="Frecuencia" span={2}>{report.city_route.frecuency}</Item>
						<Item label="Ruta" span={4}>{report.city_route.code && `(${report.city_route.code})`} {report.city_route.description}</Item>
						<Item label="Observaciones" span={4}>{report.city_route.comment}</Item>
						<Item label="Dias Promesa de Entrega" span={1}>{report.product_promise.regular_size} días</Item>
						<Item label="Dias Adicionales por T1-T2" span={1}>{report.city_route.regional_stop} días</Item>
						<Item label="Total Días" span={2}>{report.product_promise.regular_size + report.city_route.regional_stop} días</Item>
						<Item label="Ciudad">{report.city_route.city}</Item>
						<Item label="Fecha de Compra">{moment(report.params.purchase_date).format('dddd DD-MMM-YYYY')}</Item>
						<Item label="Fecha Necesidad Cliente" span={2}>{report.params.client_date ? moment(report.params.client_date).format('dddd DD-MMM-YYYY') : 'Sin Fecha'}</Item>
					</Descriptions>
				</React.Fragment>
			) : (
				<div className="p-2 bg-light">
					<Empty description="Ingrese los datos del simulador" />
				</div>
			)}
		</React.Fragment>
	)
}