import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import GeneralReport from './GeneralReport'
import ProfilesReport from './ProfilesReport'

export default function Reports() {
	return (
		<React.Fragment>
			<Switch>
				<Redirect exact path="/reportes" to="/reportes/general" />

				<Route exact path="/reportes/general" component={GeneralReport} />
				<Route exact path="/reportes/profiles" component={ProfilesReport} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}