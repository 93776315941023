import React, { useState } from 'react'
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col } from 'reactstrap'

import SimulatorForm from './partials/SimulatorForm'
import SimulatorResults from './partials/SimulatorResults'

export default function DeliverySimulator() {
	const [report, setReport] = useState(null)

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardBody>
							<CardTitle>Simulador Fecha de Entrega</CardTitle>
							<CardSubtitle className="mb-2">Ingrese los siguientes datos</CardSubtitle>
							<SimulatorForm setReport={setReport} />
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<CardTitle>Resultados</CardTitle>
							<CardSubtitle className="mb-3">Fecha estimada de entrega</CardSubtitle>
							<SimulatorResults report={report} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}