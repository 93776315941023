import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { storeQuoteState } from '../services'
import { handleError } from '../../../helpers'
import LinkInvoiceModal from './LinkInvoiceModal'

export default function LogStateModal({ visible, onClose, quote, reload }) {
	const { handleSubmit, register, errors, watch, setValue } = useForm()
	const [selectedStatusIndex, setSelectedStatusIndex] = useState(100)

	const onSubmit = values => {
		values.quote_id = quote.id
		storeQuoteState(values)
			.then(res => handleSuccess())
			.catch(error => handleError(error))
	}

	const handleSuccess = () => {
		message.success('Nuevo estado registrado exitosamente.')
		reload()
		onClose()
	}

	const statusList = [		
		{ 
			label: 'En toma de decisión',
			options: [
				'Cliente en espera de entrega de vivienda o muebles',
				'Le parece muy costoso',
				'Está en espera de un dinero',
				'Está de viaje',
				'Está comparando precios',
				'Está esperando una mejor promoción',
			]
		},
		{ 
			label: 'Cerrado: Competencia',
			options: [
				'La competencia le da obsequios',
				'Cambió la referencia de producto',
				'Cliente busca entrega inmediata',
				'Mejor precio',
				'Mejor fecha de entrega',
				'Por cercanía a su vivienda',
				'Por mejor especificaciones de producto (confort, altura, vistoso...)',
			],
			selectBrand: true
		},
		{ 
			label: 'Cerrado: Sin Contacto',
			options: [
				'No contesta',
				'Llamada rechazada',
				'Número errado ',
				'Cliente molesto , indica no llamar mas.',
			]
		},
		{ 
			label: 'Cerrado: Desiste de Compra',
			options: [
				'Cotiza para otra persona',
				'No dispone del dinero para la compra',
				'Por curiosidad cotizó,  pero no va a comprar',
				'Cliente indeciso, lo está pensando',
				'Cliente no es apto para crédito',
				'Cliente no tiene otras formas de pago',
				'Cliente espera aprobación de familiar o acompañante',
				'Cliente no hará la compra en el momento, se contacta mas adelante',
				'Cliente pospone compra por temas personales',
				'Cliente indica se acerca a la tienda en otro momento',
				'Cliente interesado en comprar con Brilla',
				'Otro familiar lo compró o se lo regalaron',
				'Por viaje / Mudanza',
				'No hay cubrimiento de zona para la entrega',
			]			
		},
		{
			label: 'Cerrado: No acepta tiempo entrega',
			options: [
				'Cliente no acepta tiempo entrega',
			]
		},
		{ 
			label: 'Cerrado: Compro en Spring',
			options: [
				'Venta efectiva',
				'Seguimiento efectivo',
				'En otra tienda Spring',
			]
		},
		{ 
			label: 'Cerrado: Compro en Spring C.Ind',
			options: [
				'Compro en canal indirecto',
			]
		},
	]

	if(!quote) return null

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Estado de Cotización #{quote.number}</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Estado <span className="text-primary">*</span></Form.Label>
							<Form.Control
								as="select"
								name="status"
								defaultValue={quote.current_state}
								ref={register({ required: true })}
								onChange={e => statusList.map((status,i) => status.label === e.target.value && setSelectedStatusIndex(i))}
							>
								<option value="">Estado Inicial</option>
								{ statusList.map((status,i) => (
									<option key={i}>{status.label}</option>) 
								)}
							</Form.Control>
							{ errors.status && <Form.Text className="text-warning">Seleccione un estado diferente al inicial.</Form.Text> }
						</Form.Group>
						{ statusList[ selectedStatusIndex ] && statusList[ selectedStatusIndex ].selectBrand && (
							<Form.Group>
								<Form.Label>Seleccione la marca <span className="text-primary">*</span></Form.Label>
								<Form.Control
									as="select"
									name="terciary_status"
									ref={register({ required:true })}
								>
									<option>Ensueño</option>
									<option>Paraiso</option>
									<option>Americana</option>
									<option>Comodísimos</option>
									<option>Happy Sleep</option>
									<option>Tugo</option>
									<option>Alkompra</option>
									<option>Espumados del valle</option>
									<option>Dormilife</option>
									<option>Alkosto</option>
									<option>Ramble</option>
									<option>Dormiluna</option>
									<option>Pullman</option>
									<option>Éxito</option>
									<option>El Dorado</option>
									<option>Fantasía</option>
									<option>Jamar</option>
									<option>Falabella</option>
									<option>Desconocida</option>
								</Form.Control>
							</Form.Group>
						)}
						{ statusList[ selectedStatusIndex ] && statusList[ selectedStatusIndex ].options && (
							<Form.Group>
								<Form.Label>Estado Adicional <span className="text-primary">*</span></Form.Label>
								<Form.Control
									as="select"
									name="secondary_status"
									ref={register({ required:true })}
								>
									{ statusList[ selectedStatusIndex ].options.map((status,i) => (
										<option key={i}>{status}</option>
									)) }
								</Form.Control>
							</Form.Group>
						)}
						<Form.Group>
							<Form.Label>Comentario <span className="text-primary">*</span></Form.Label>
							<Form.Control 
								as="textarea"
								name="comment"
								ref={register({ required: true })}
							/>
							{ errors.comment && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Button color="primary">Guardar Estado Nuevo</Button>
					</Form>
				</ModalBody>
			</Modal>
			<LinkInvoiceModal 
				visible={ (watch('status')==='Cerrado: Compro en Spring') ? true : false }
				onClose={()=>setValue('status','')}
				quoteId={quote.id}
				status={"Cerrado: Compro en Spring"}
				handleSuccess={handleSuccess}
				statusList={statusList}
				selectedStatusIndex={selectedStatusIndex}
			/>
		</React.Fragment>
	)
}