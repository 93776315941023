import springApi, { getDomain } from '../../services/springApi'

export const getUsers = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/users', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchUsers = async (name, token) => (
	fetch( getDomain()+`/users?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(user => ({
				label: `${user.name} [${user.code}]`,
				value: user.id,
			})),
		)
)