import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, CardSubtitle, Spinner } from 'reactstrap'
import { Divider } from 'antd'
import { Doughnut } from 'react-chartjs-2'

export default function QuoteStatesWidget({ report }) {
	const [total, setTotal] = useState(1)

	useEffect(() => {
		if(report && report.states) setTotal(parseInt(report.states.inicial) + 
			parseInt(report.states.otros) +
			parseInt(report.states.seguimiento) +
			parseInt(report.states.competencia) +
			parseInt(report.states.spring) +
			parseInt(report.states.sincontacto) +
			parseInt(report.states.desistio) +
			parseInt(report.states.entrega))
	}, [report])
	
	const data = report ? {
		labels: [
			`Estado Inicial ${report.states.inicial > 0 ? round((report.states.inicial + report.states.otros) / total * 100) : 0}%`,
			`En Seguimiento ${report.states.seguimiento > 0 ? round(report.states.seguimiento / total * 100) : 0}%`,
			`Cerrado: Competencia ${report.states.competencia > 0 ? round(report.states.competencia / total * 100) : 0}%`,
			`Cerrado: Compro Spring ${report.states.spring > 0 ? round(report.states.spring / total * 100) : 0}%`,
			`Cerrado: Sin Contacto ${report.states.sincontacto > 0 ? round(report.states.sincontacto / total * 100) : 0}%`,
			`Cerrado: Desiste de Compra ${report.states.desistio > 0 ? round(report.states.desistio / total * 100) : 0}%`,
			`Cerrado: No acepta tiempo entrega ${report.states.entrega > 0 ? round(report.states.entrega / total * 100) : 0}%`,
		],
		datasets: [
		  {
			 label: '# cotizaciones',
			 data: [
				 parseInt(report.states.inicial) + parseInt(report.states.otros),
				 report.states.seguimiento,
				 report.states.competencia, 
				 report.states.spring, 
				 report.states.sincontacto,
				 report.states.desistio,
				 report.states.entrega,
			],
			backgroundColor: [
				 'rgba(185, 185, 185, 0.6)',
				 'rgba(252, 186, 3, 0.6)',
				 'rgba(224, 136, 20, 0.6)',
				 'rgba(153, 224, 20, 0.6)',
				 'rgba(20, 166, 224, 0.6)',
				 'rgba(139, 20, 224, 0.6)',
				 'rgba(20, 207, 224, 0.6)',

			],
			 borderColor: [
				'rgba(185, 185, 185, 0.8)',
				'rgba(252, 186, 3, 0.8)',
				'rgba(224, 136, 20, 0.8)',
				'rgba(153, 224, 20, 0.8)',
				'rgba(20, 166, 224, 0.8)',
				'rgba(139, 20, 224, 0.8)',
				'rgba(20, 207, 224, 0.8)',
			 ],
			 borderWidth: 1,
		  },
		],
	} : {}
	 
	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Reporte de Estados</CardTitle>
					<CardSubtitle>Estado actual de cotizaciones</CardSubtitle>
					<Divider className="mt-2 mb-3" />
					{ report ? <Doughnut data={data} /> : <Spinner size="sm" />}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}

const round = value => Math.round((value + Number.EPSILON) * 1) / 1