import springApi from '../../services/springApi'

export const mostQuotedProducts = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/reports/most_quoted_products', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const productsBestSellers = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/reports/products_best_sellers', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)