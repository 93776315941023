import React, { useState, useEffect } from 'react'
import { Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Empty } from 'antd'

import { ReferenceBox, ResultsBox } from './ProductReferenceStep'
import { getRates } from '../../../Rates/services'
import { handleError } from '../../../../helpers'

export default function ProductRateStep({ productId, storeId, handleStepBack, setRate}) {
	const [rates, setRates] = useState(null)

	useEffect(()=>{
		!rates && getRates({
			"filter[product_id]": productId,
			"filter[store_id]": storeId,
			"filter[active]": 1,
			per_page: 100,
			sort: '-net_price'
		})
			.then(res => setRates(res.data.data))
			.catch(error => handleError(error))
	})

	return (
		<React.Fragment>
			<ResultsBox className="bg-light mt-3">
				{ rates ? (
					<React.Fragment>
						{ rates.length > 0 ? (
							<React.Fragment>
								{ rates.map( (rate, i) => (
									<ReferenceBox key={i} className="text-link text-dark" onClick={()=>setRate(rate)}>
										<div className="float-right pt-1">
											<Button color="primary" outline>Seleccionar</Button>
										</div>
										<h6 className="mb-0">
											{rate.description}
										</h6>
										<p className="mb-0 text-muted">
											{`$ ${rate.net_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
											{" | "}
											<span className="line-through">{`$ ${rate.gross_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</span>
											{" "}
											<small>(Descuento {rate.discount}%)</small>
										</p>
									</ReferenceBox>
								)) }
							</React.Fragment>
						) : <Empty description="No se encontraron tarifas activas" />}
					</React.Fragment>
				) : <Spinner size="sm" />}
			</ResultsBox>
			<div className="mt-2 mb-0">
				<Button color="primary" outline onClick={handleStepBack}>
					<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
					Volver atrás
				</Button>
			</div>
		</React.Fragment>
	)
}